import React from 'react'

export default function Show() {
  return (
    <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.16796 0.335819L6.001 0.333008C3.58547 0.333008 1.40857 2.03814 0.202038 4.82808C0.154659 4.93763 0.154659 5.06171 0.202038 5.17127L0.285643 5.35947C1.47689 7.97067 3.53551 9.58721 5.83172 9.66353L5.99867 9.66634C8.41421 9.66634 10.5911 7.96121 11.7976 5.17127C11.8456 5.06033 11.845 4.93457 11.7959 4.82411L11.7147 4.64129C10.5205 2.02521 8.46102 0.412029 6.16796 0.335819ZM6.00516 1.2019L6.14454 1.2049L6.3003 1.213C8.16534 1.34506 9.88056 2.72797 10.9168 4.99922L10.9114 5.01323C9.84916 7.33334 8.07479 8.72449 6.15093 8.7943L6.00224 8.79654L5.85229 8.79453L5.69693 8.78645C3.88983 8.65845 2.22363 7.35314 1.18108 5.20957L1.08241 4.99922L1.17375 4.80502C2.2731 2.53408 4.06994 1.2024 6.00516 1.2019ZM5.9996 2.7326C4.73936 2.7326 3.71818 3.74741 3.71818 4.99987C3.71818 6.25185 4.73947 7.26655 5.9996 7.26655C7.25979 7.26655 8.2816 6.25178 8.2816 4.99987C8.2816 3.74748 7.2599 2.7326 5.9996 2.7326ZM5.9996 3.60198C6.77669 3.60198 7.4066 4.22768 7.4066 4.9999C7.4066 5.7716 6.77663 6.39723 5.9996 6.39723C5.22272 6.39723 4.59318 5.77176 4.59318 4.9999C4.59318 4.22753 5.22265 3.60198 5.9996 3.60198Z'
        fill='#555555'
      />
    </svg>
  )
}
