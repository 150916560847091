import React from 'react'
import DashboardLatestItem from './DashboardLatestItem'
import Download from './icons/Download'
export default function DashboardLatest() {
  return (
    <div className='h-full  flex flex-shrink flex-col pl-6  '>
      <div className='flex flex-col  pr-6 h-[calc(100%-122px)] overflow-hidden overflow-y-scroll scrl  '>
        <DashboardLatestItem />
        <DashboardLatestItem />
        <DashboardLatestItem />
        <DashboardLatestItem />
        <DashboardLatestItem />
        <DashboardLatestItem />
        <DashboardLatestItem />
        <DashboardLatestItem />
        <DashboardLatestItem />
        <DashboardLatestItem />
        <DashboardLatestItem />
        <DashboardLatestItem />
      </div>
      <div className='pr-6 mb-6 pt-2'>
        <button className='flex items-center justify-between text-sgray h-9 pl-2.5 pr-3 w-full rounded-md bg-dgray '>
          <button className='bg-[#2D2D2D] rounded-full w-[22px] h-[22px] centering text-[10px] text-dgreen'>
            i
          </button>
          <span className='text-[10px] '>Please Dowload!</span>
          <Download />
        </button>
      </div>
    </div>
  )
}
