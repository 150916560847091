import React, { useState } from 'react'
import TaskModal from './TaskModal'
import TaskButton from './TaskButton'
import TaskTableItem from './TaskTableItem'
import { useGlobal } from '../GlobalContext'
export default function TasksTable() {
  const { tasks, activeTaskGr } = useGlobal()
  const [modal, showModal] = useState(false)
  const closeModal = (e) => {
    showModal(false)
  }

  return (
    <div className='w-full h-full flex flex-col justify-between'>
      {tasks.length > 0 ? (
        <>
          <h3 className='text-[11px] leading-[13px] pb-1 '>{activeTaskGr.name}</h3>
          <div className='h-full mb-auto overflow-y-scroll   scrl'>
            <table className='w-full table-auto tasksTable pr-2  '>
              <thead className='sticky top-0 left-0 '>
                <tr className='w-full pl-[18px] pr-[16px] py-2.5 bg-dgray h-[35px] text-[10px] leading-[12px] text-sgray'>
                  <th className=''>ID</th>
                  <th className=']'>Site</th>
                  <th className=']'>Product</th>
                  <th className=''>Profile</th>
                  <th className=''>Sizes</th>
                  <th className=''>Proxies</th>
                  <th className=''>Status</th>
                  <th className=''></th>
                </tr>
              </thead>
              <tbody>
                {activeTaskGr &&
                  activeTaskGr?.taskArr.map((task, i) => <TaskTableItem key={i} task={task} />)}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className='w-full h-full centering'>There are no task group created...</div>
      )}
      <div className='flex items-center mt-3'>
        <TaskButton addClass='mr-2' key='start'>
          <img className='' src='./icons/start.svg' alt='start' />
        </TaskButton>
        <TaskButton addClass='mr-2' key='pause'>
          <img className='' src='./icons/pause.png' alt='pause' />
        </TaskButton>
        <TaskButton addClass='mr-2' key='edit'>
          <div className='w-4 h-4 flex justify-center items-center bg-dblue rounded-sm'>
            <img className='' src='./icons/edit.png' alt='edit' />
          </div>
        </TaskButton>
        <TaskButton addClass='mr-auto' key='delete'>
          <div className='w-4 h-4 flex justify-center items-center bg-dbrown rounded-sm'>
            <img className='' src='./icons/Delete.svg' alt='delete' />
          </div>
        </TaskButton>
        <button
          onClick={() => {
            showModal(true)
          }}
          className='px-[30px] py-3 bg-dgray rounded-[5px] flex items-center justify-center text-dblue text-[10px] leading-[12px] hover:bg-lgray transition-colors'>
          Create Tasks
        </button>
      </div>
      {modal && (
        <div
          onClick={(e) => {
            if (e.target.classList[0] === 'cont') {
              closeModal()
            }
          }}
          className='cont z-20 fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center'>
          <TaskModal closeModal={closeModal} />
        </div>
      )}
    </div>
  )
}
