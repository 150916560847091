import React, { useState } from 'react'
import { useSession } from '../SessionTimeContext'
export default function SessionPixel() {
  const { setTime } = useSession()
  const [today, setDate] = useState(new Date())
  const [session, setSession] = useState(new Date())
  const diff = ((session - today) / 1000).toFixed(0)
  const hours = Math.floor(diff / 3600).toString()
  const minutes = Math.floor((diff - hours * 3600) / 60).toString()
  const seconds = (diff - hours * 3600 - minutes * 60).toString()
  const adZero = (time) => (time.length < 2 ? `0${time}` : time)
  const time = `${adZero(hours)}:${adZero(minutes)}:${adZero(seconds)}`

  React.useEffect(() => {
    const timer = setInterval(() => {
      setSession(new Date())
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])
  React.useEffect(() => {
    setTime(time)
  }, [time, setTime])
  return <div className='fixed top-0 left-0 h-px w-px'></div>
}
