import React from 'react'
import CaptchaSideBar from './CaptchaSideBar'
import DashboardSidebar from './DashboardSidebar'
import ProfilesSideBar from './ProfilesSideBar'
import ProxySideBar from './ProxySideBar'
import Settings from './Settings'
import TaskSideBar from './TaskSideBar'

export default function SideBarHeader({ current }) {
  const sideBarItems = [
    {
      id: 'dashboard',
      component: DashboardSidebar,
      heading: 'Hi, UserName',
      text: 'Welcome back to RhydeAIO!',
    },
    { id: 'tasks', component: TaskSideBar, heading: 'Tasks' },
    { id: 'search', component: null, heading: 'Monitors' },
    { id: 'profiles', component: ProfilesSideBar, heading: 'Profiles' },
    { id: 'proxies', component: ProxySideBar, heading: 'Proxies' },
    { id: 'captcha', component: CaptchaSideBar, heading: 'Captcha' },
    { id: 'settings', component: Settings, heading: 'Settings' },
  ]
  return (
    <div className='min-w-[245px] flex-shrink-0 relative flex flex-col h-full'>
      <div className='empty h-[83px] '></div>
      <div className='glassed absolute w-full h-[120px] top-0 left-0 box-border z-[1]'>
        {sideBarItems.map(
          (item) =>
            item.id === current && (
              <div key={item.id} className='py-6 px-[29px]'>
                <h3 className='text-sm'>{item.heading}</h3>
                <p className='text-xs'>{item.text ? item.text : `Manage your ${item.heading}`} </p>
              </div>
            )
        )}
      </div>
    </div>
  )
}
