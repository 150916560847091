import React from 'react'
import Delete from './icons/Delete'
import Edit from './icons/Edit'
import Play from './icons/Play'

export default function MonitorsItem({ data }) {
  return (
    <div className='rounded-11 overflow-hidden flex flex-col  text-[10px] bg-dgray'>
      <div className='flex flex-col  pt-[18px] px-6'>
        <div className='flex justify-between pb-3'>
          <div className='flex flex-col'>
            <p className='text-dblue pb-1'>{data.site}</p>
            <p className='text-sgray'>{data.product} </p>
          </div>
          <img className='object-cover w-9 h-auto' src='./icons/sneaker.png' alt={data.product} />
        </div>
        <p className='pb-3'>
          <span className='text-dblue mr-0.5'>{data.host}</span>
          <span className='text-dbrown mr-0.5'>{data.tasks}</span>
          <span className='text-sgreen'>{data.profile}</span>
        </p>
        <div className='flex justify-between'>
          <div className='flex items-center pb-3 mr-auto'>
            <button className='w-5 h-5 flex items-center justify-center mr-px focus:outline-none'>
              <Delete color={'#555'} />
            </button>
            <button
              className={`w-4 h-4 rounded-sm bg-sgray flex items-center justify-center focus:outline-none mr-1`}>
              <Edit color='black' width={8} height={8} />
            </button>
            <button
              className={`w-4 h-4 rounded-sm bg-sgray flex items-center justify-center focus:outline-none`}>
              <Play color='black' width={8} height={8} />
            </button>
          </div>
          <span className='text-dblue'>Searching...</span>
        </div>
      </div>
    </div>
  )
}
