import React from 'react'
import MasterCard from './icons/MasterCard'
export default function SettingsLicenseType() {
  return (
    <div className='w-full h-full  rounded-md bg-dgray flex flex-col justify-center p-6   text-[11px]'>
      <div className='flex'>
        <div className='flex flex-col'>
          <p className='leading-[13px] pb-1'>License Type</p>
          <span className=' leading-[12px] text-sgray pb-4'>
            Renewal <span className='ml-1 text-sgreen'>20$/month</span>{' '}
          </span>
          <p className='leading-[13px] pb-1'>Expiration</p>
          <span className='leading-[12px] text-sgray pb-4'>June 24,2021</span>
        </div>
        <div className='ml-auto h-[35px] rounded-md border border-sgray py-2.5 px-3 flex items-center'>
          <MasterCard />
          <p className='ml-2.5 text-sgray'>Mastercard Ending with 1234</p>
          <button className=' ml-10 text-dblue'>Dashboard</button>
        </div>
      </div>
      <div className='w-full h-1 relative bg-dblack rounded-full'>
        <div
          style={{ width: '67%' }}
          className='absolute h-1 top-0 left-0 bg-gradient-to-r from-[#526AE3] to-[#8CA9F3] rounded-full'></div>
      </div>
    </div>
  )
}
