import React from 'react'

export default function Edit({ color = '#fff', width = '10', height = '10' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.39062 0.666504C6.14937 0.666504 5.90812 0.758379 5.72396 0.942546L5.33333 1.33317L6.66667 2.6665L7.05729 2.27588C7.42529 1.90788 7.42529 1.31088 7.05729 0.942546C6.87313 0.758379 6.63188 0.666504 6.39062 0.666504ZM4.83333 1.83317L1 5.6665V6.99984H2.33333L6.16667 3.1665L4.83333 1.83317Z'
        fill={color}
      />
    </svg>
  )
}
