import React, { useState } from 'react'
import ProxyGroup from './ProxyGroup'
import { useGlobal } from '../GlobalContext'
import ProxyGroupModal from './ProxyGroupModal'

export default function ProxySideBar() {
  const defaultGroup = {
    name: 'Proxy Group 1',
    proxiesArr: [
      {
        id: 1,
        url: '192.168.1.1:9999:BRUH:PSSWRD',
      },
      {
        id: 2,
        url: '192.168.1.1:9999:BRUH:PSSWRD',
      },
      {
        id: 3,
        url: '192.168.1.1:9999:BRUH:PSSWRD',
      },
    ],
  }
  const [modal, showModal] = useState(false)
  const { proxiesGroups, setProxiesGroups, activeProxiesGr, setActiveProxiesGr } = useGlobal()
  const closeModal = (e) => {
    showModal(false)
  }
  const addGroup = (name) => {
    const newGroup = { ...defaultGroup, name, id: Date.now() }
    if (proxiesGroups.length < 1) {
      setActiveProxiesGr(newGroup)
    }
    setProxiesGroups((prev) => [...prev, newGroup])
  }
  return (
    <div className='flex flex-col pl-6 py-4 min-h-[550px] h-full bg-dg'>
      <div className='flex justify-between pr-6'>
        <span className='text-xs '>Proxies Groups</span>
        <span
          onClick={() => {
            showModal(true)
          }}
          className='text-[10px] add-plus-icon text-dblue cursor-pointer'>
          Add Group
        </span>
      </div>
      <div className='w-full flex-shrink-0 flex pr-6 '>
        <span className='h-0.5 w-full bg-dblack my-[11px]'></span>
      </div>
      <div className='h-[calc(100%-50px)] overflow-y-scroll scrl pr-6'>
        {proxiesGroups &&
          proxiesGroups?.length > 0 &&
          proxiesGroups.map((group, i) => (
            <ProxyGroup
              key={group.id}
              proxyGroup={group}
              idx={i}
              active={group.id === activeProxiesGr.id}
              setActive={setActiveProxiesGr}
            />
          ))}
      </div>
      {modal && (
        <div
          onClick={(e) => {
            if (e.target.classList[0] === 'cont') {
              closeModal()
            }
          }}
          className='cont z-10 fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center'>
          <ProxyGroupModal closeModal={closeModal} addGroup={addGroup} />
        </div>
      )}
    </div>
  )
}
