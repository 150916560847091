import React from 'react'
import NotifyItem from './NotifyItem'

export default function AnnouncmentModal({ closeModal }) {
  return (
    <div className='w-[286px] max-h-[373px] glassed py-4 pl-5 flex flex-col rounded-[5px] absolute top-[11%] right-[3%]'>
      <div className='flex justify-between items-center text-[10px]  border-dblack flex-shrink-0 mb-2.5 pr-5 '>
        <h3 className='text-[11px]'>Notifications</h3>
        <h5 className='text-dblue'>3 new</h5>
      </div>
      <div className='w-full pr-5 flex h-0.5 flex-shrink-0 mb-2.5'>
        <div className='bg-wht bg-opacity-60 h-full w-full'></div>
      </div>
      <div className='h-[calc(100%-56px)] overflow-y-scroll scrl pr-5'>
        <NotifyItem />
        <NotifyItem />
        <NotifyItem />
      </div>
      <button
        onClick={closeModal}
        className='close mt-3  text-[10px] leading-none h-5 self-center flex-shrink-0  text-dblue  px-[30px] '>
        Close
      </button>
    </div>
  )
}
