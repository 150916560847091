import React, { useState } from 'react'
import TaskGroupInput from './TaskGroupInput'
import TaskGroupModal from './TaskGroupModal'
import { useGlobal } from '../GlobalContext'

export default function TaskSideBar() {
  const [modal, showModal] = useState(false)
  const { tasks, setTasks, activeTaskGr, setActiveTaskGr } = useGlobal()
  const defaultGroup = {
    name: 'Task Group 1',
    id: tasks.length + 1,
    taskArr: [
      {
        id: 'A1',
        profile: 'Profile 1',
        site: 'Footlocker US',
        product: 'Nike SB Dunk Low Pro Blue',
        sizes: [9],
        proxy: 'Proxy List 1',
      },
      {
        id: 'A1',
        profile: 'Profile 1',
        site: 'Footlocker US',
        product: 'Nike SB Dunk Low Pro Blue',
        sizes: [7],
        proxy: 'Proxy List 1',
      },
    ],
  }
  const closeModal = (e) => {
    showModal(false)
  }
  const addGroup = (name) => {
    if (tasks.length < 1) {
      setActiveTaskGr({ ...defaultGroup, name })
    }
    setTasks((prev) => [...prev, { ...defaultGroup, name }])
  }
  return (
    <div className='flex bg-dg flex-col pl-6 py-4  min-h-[550px] h-full relative z-20'>
      <div className='flex justify-between pr-6'>
        <span className='text-xs '>Tasks Groups</span>
        <span
          onClick={() => {
            showModal(true)
          }}
          className='text-[10px] add-plus-icon text-dblue cursor-pointer'>
          Add Group
        </span>
      </div>
      <div className='h-0.5 w-full  my-[11px] pr-6'>
        <div className='h-full w-full bg-dblack'></div>
      </div>
      <div className='h-full overflow-y-scroll scrl pr-6 '>
        {tasks &&
          tasks.length > 0 &&
          tasks.map((task, i) => (
            <TaskGroupInput
              key={task.id}
              taskGroup={task}
              idx={i}
              active={task.id === activeTaskGr.id}
              setActive={setActiveTaskGr}
            />
          ))}
      </div>
      {modal && (
        <div
          onClick={(e) => {
            if (e.target.classList[0] === 'cont') {
              closeModal()
            }
          }}
          className='cont z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center'>
          <TaskGroupModal closeModal={closeModal} addGroup={addGroup} />
        </div>
      )}
    </div>
  )
}
