import React from 'react'

export default function Download() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Iconly/Bold/Download'>
        <g id='Download'>
          <g id='Download_2'>
            <path
              d='M7.4869 4.86018V2.18751C7.4869 1.90332 7.71356 1.6665 8.00023 1.6665C8.2569 1.6665 8.47435 1.8655 8.50881 2.11756L8.51356 2.18751V4.86018L11.7002 4.86039C13.2868 4.86039 14.5904 6.15969 14.6636 7.78011L14.6668 7.9239V11.2834C14.6668 12.9152 13.4086 14.2546 11.8455 14.3298L11.7068 14.3332H4.2935C2.70683 14.3332 1.40956 13.0404 1.33672 11.414L1.3335 11.2697L1.3335 7.91701C1.3335 6.28524 2.58544 4.93931 4.14816 4.86373L4.28683 4.86039H7.48683V9.12865L6.42016 8.02716C6.22016 7.82063 5.8935 7.82063 5.6935 8.02716C5.5935 8.13043 5.54683 8.26811 5.54683 8.4058C5.54683 8.51044 5.5767 8.61948 5.63984 8.71179L5.6935 8.77755L7.6335 10.7878C7.72683 10.891 7.86016 10.9461 8.00016 10.9461C8.11127 10.9461 8.22238 10.9079 8.31035 10.8353L8.36016 10.7878L10.3002 8.77755C10.5002 8.57102 10.5002 8.23369 10.3002 8.02716C10.1183 7.83941 9.83184 7.82234 9.63099 7.97596L9.5735 8.02716L8.5135 9.12865V4.86039L7.4869 4.86018Z'
              fill='white'
            />
            <path
              d='M7.4869 4.86018V2.18751C7.4869 1.90332 7.71356 1.6665 8.00023 1.6665C8.2569 1.6665 8.47435 1.8655 8.50881 2.11756L8.51356 2.18751V4.86018L11.7002 4.86039C13.2868 4.86039 14.5904 6.15969 14.6636 7.78011L14.6668 7.9239V11.2834C14.6668 12.9152 13.4086 14.2546 11.8455 14.3298L11.7068 14.3332H4.2935C2.70683 14.3332 1.40956 13.0404 1.33672 11.414L1.3335 11.2697L1.3335 7.91701C1.3335 6.28524 2.58544 4.93931 4.14816 4.86373L4.28683 4.86039H7.48683V9.12865L6.42016 8.02716C6.22016 7.82063 5.8935 7.82063 5.6935 8.02716C5.5935 8.13043 5.54683 8.26811 5.54683 8.4058C5.54683 8.51044 5.5767 8.61948 5.63984 8.71179L5.6935 8.77755L7.6335 10.7878C7.72683 10.891 7.86016 10.9461 8.00016 10.9461C8.11127 10.9461 8.22238 10.9079 8.31035 10.8353L8.36016 10.7878L10.3002 8.77755C10.5002 8.57102 10.5002 8.23369 10.3002 8.02716C10.1183 7.83941 9.83184 7.82234 9.63099 7.97596L9.5735 8.02716L8.5135 9.12865V4.86039L7.4869 4.86018Z'
              fill='url(#paint0_linear)'
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='1.3335'
          y1='14.3332'
          x2='11.7602'
          y2='16.3914'
          gradientUnits='userSpaceOnUse'>
          <stop stopColor='#526AE3' />
          <stop offset='1' stopColor='#8CA9F3' />
        </linearGradient>
      </defs>
    </svg>
  )
}
