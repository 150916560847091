import React from 'react'

export default function MasterCard() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6667 3.3335C10.0538 3.3335 9.447 3.4542 8.88081 3.68872C8.31462 3.92325 7.80018 4.26699 7.36684 4.70033C6.9335 5.13367 6.58975 5.64812 6.35523 6.21431C6.12071 6.78049 6 7.38733 6 8.00016C6 8.613 6.12071 9.21983 6.35523 9.78602C6.58975 10.3522 6.9335 10.8667 7.36684 11.3C7.80018 11.7333 8.31462 12.0771 8.88081 12.3116C9.447 12.5461 10.0538 12.6668 10.6667 12.6668C11.2795 12.6668 11.8863 12.5461 12.4525 12.3116C13.0187 12.0771 13.5332 11.7333 13.9665 11.3C14.3998 10.8667 14.7436 10.3522 14.9781 9.78602C15.2126 9.21983 15.3333 8.613 15.3333 8.00016C15.3333 7.38733 15.2126 6.78049 14.9781 6.21431C14.7436 5.64812 14.3998 5.13367 13.9665 4.70033C13.5332 4.26699 13.0187 3.92325 12.4525 3.68872C11.8863 3.4542 11.2795 3.3335 10.6667 3.3335Z'
        fill='#FF9800'
      />
      <path
        d='M5.33317 3.3335C4.72034 3.3335 4.1135 3.4542 3.54731 3.68872C2.98113 3.92325 2.46668 4.26699 2.03334 4.70033C1.6 5.13367 1.25625 5.64812 1.02173 6.21431C0.787211 6.78049 0.666504 7.38733 0.666504 8.00016C0.666504 8.613 0.787211 9.21983 1.02173 9.78602C1.25625 10.3522 1.6 10.8667 2.03334 11.3C2.46668 11.7333 2.98113 12.0771 3.54731 12.3116C4.1135 12.5461 4.72034 12.6668 5.33317 12.6668C5.94601 12.6668 6.55284 12.5461 7.11903 12.3116C7.68521 12.0771 8.19966 11.7333 8.633 11.3C9.06634 10.8667 9.41009 10.3522 9.64461 9.78602C9.87913 9.21983 9.99984 8.613 9.99984 8.00016C9.99984 7.38733 9.87913 6.78049 9.64461 6.21431C9.41009 5.64812 9.06634 5.13367 8.633 4.70033C8.19966 4.26699 7.68521 3.92325 7.11903 3.68872C6.55284 3.4542 5.94601 3.3335 5.33317 3.3335Z'
        fill='#D50000'
      />
      <path
        d='M6 8.00001C6 9.58501 6.792 10.9833 8 11.8267C9.208 10.9833 10 9.58501 10 8.00001C10 6.41501 9.208 5.01667 8 4.17334C6.792 5.01667 6 6.41501 6 8.00001Z'
        fill='#FF3D00'
      />
    </svg>
  )
}
