import React from 'react'

export default function LineChartSm() {
  return (
    <svg width='57' height='22' viewBox='0 0 57 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        strokeLinecap='round'
        d='M2 21.1738C2.5 20 7.55556 8.5 13 8.5C18.4444 8.5 21.5556 10.5 27 10.5C32.4444 10.5 35.581 2 41.0254 2C46.4699 2 53.5 12.3262 55.5 16'
        stroke='#25BF7B'
        strokeWidth='3'
      />
    </svg>
  )
}
