import React from 'react'
import Checked from './icons/Checked'
import Delete from './icons/Delete'
import ItemWrapper from './ItemWrapper'
import { deleteGroup } from './logic/deleteGroup'
import { useGlobal } from '../GlobalContext'
export default function ProfileGroup({ profileGroup, setActive, active = false, idx }) {
  const { profileGroups, setProfileGroups, activeProfileGr, setActiveProfileGr } = useGlobal()
  const handleDelete = async () => {
    const cleanedArray = deleteGroup(profileGroups, profileGroup.id)
    await setProfileGroups(cleanedArray)
    if (profileGroup.id === activeProfileGr.id) {
      setActiveProfileGr({ ...cleanedArray[0] })
    }
  }
  const handleSelect = () => {
    setActive(profileGroup)
  }
  return (
    <ItemWrapper>
      <div className='flex items-center text-[11px] px-[15px]  py-[10px] justify-between'>
        <div className='flex flex-col  leading-[13px] text-[11px] py-0.5'>
          <span
            className={
              active
                ? 'bg-clip-text text-transparent bg-gradient-to-r from-[#526AE3] to-[#8CA9F3]'
                : ' '
            }>
            {profileGroup.name}
          </span>
          <span className='text-[10px] leading-[12px]'>
            {profileGroup.profilesArr.length} Profiles
          </span>
        </div>
        <div className='flex'>
          <button
            onClick={handleDelete}
            className='w-[22px] h-[22px] rounded-full border bg-gray-700 border-gray-500 flex items-center justify-center mr-1.5'>
            <Delete />
          </button>
          <button
            onClick={handleSelect}
            className={`w-[22px] h-[22px] rounded-full border ${
              active ? 'bg-lblue' : 'bg-gray-700'
            } border-gray-500 flex items-center justify-center`}>
            {active ? <Checked bg /> : ''}
          </button>
        </div>
      </div>
    </ItemWrapper>
  )
}
