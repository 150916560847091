import React, { useState } from 'react'
import ProfileItem from './ProfileItem'
import ProfileModal from './ProfileModal'
import { useGlobal } from '../GlobalContext'
export default function ProfilesMain() {
  const { profileGroups, activeProfileGr } = useGlobal()
  const [modal, showModal] = useState(false)
  const closeModal = (e) => {
    showModal(false)
  }
  return (
    <div className='flex flex-col h-full'>
      {profileGroups && profileGroups?.length > 0 ? (
        <>
          <h3 className='text-[11px] leading-[13px]'>
            {activeProfileGr.name} ({activeProfileGr.profilesArr?.length})
          </h3>
          <span className='h-0.5 w-full bg-dblack my-[11px]'></span>
          <div className='grid gridProfiles  gap-x-[22px] gap-y-[18px] mb-auto overflow-y-scroll scrl'>
            {activeProfileGr &&
              activeProfileGr?.profilesArr.map((profile, i) => (
                <ProfileItem key={i} data={profile} />
              ))}
          </div>
        </>
      ) : (
        <div className='w-full h-full centering'>There is no Profiles group created...</div>
      )}
      <div className='flex justify-end mt-3'>
        <button
          onClick={() => {
            showModal(true)
          }}
          className='px-[30px] py-3 bg-dgray rounded-[5px] flex items-center justify-center text-dblue text-[10px] leading-[12px] hover:bg-lgray transition-colors'>
          Create Profile
        </button>
      </div>
      {modal && (
        <div
          onClick={(e) => {
            if (e.target.classList[0] === 'cont') {
              closeModal()
            }
          }}
          className='cont z-10 fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center'>
          <ProfileModal closeModal={closeModal} />
        </div>
      )}
    </div>
  )
}
