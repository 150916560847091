import React, { useState } from 'react'
import Input from './Input'

export default function MonitorsGroupModal({ closeModal, addGroup }) {
  const [name, setName] = useState('')
  const handleAdd = () => {
    addGroup(name)
    closeModal()
  }
  return (
    <div className='min-w-[420px] bg-dg pt-4 pb-6 px-[30px] flex flex-col rounded-[5px]'>
      <div className='flex items-center text-[10px] border-b-2 pb-2 border-dblack'>
        <div className='mr-auto flex flex-col '>
          <h3 className=' text-[#777]'>Create Profile Group</h3>
          <h5 className=''>Enter Information</h5>
        </div>
        <button onClick={closeModal} className='text-dblue'>
          Close
        </button>
      </div>
      <div className='flex pt-3 '>
        <div className='w-full mr-3'>
          <Input setValue={setName} value={name} plchldr='Enter Name' />
        </div>
        <button
          onClick={handleAdd}
          className='flex-shrink-0 w-[120px] text-[10px] leading-none h-9 text-dblue  rounded-md bg-dgray hover:bg-lgray transition-colors '>
          Create Group
        </button>
      </div>
    </div>
  )
}
