import React from 'react'

export default function ItemWrapper({ children, active = false }) {
  return (
    <div
      className={
        active
          ? ' max-h-[50px] bg-dblack rounded-lg  mb-[11px]  mt-1 '
          : ' max-h-[50px] bg-dgray rounded-lg  mb-[11px]  mt-1'
      }>
      {children}
    </div>
  )
}
