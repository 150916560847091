import React from 'react'
import ArrowDropDown from './icons/ArrowDropDown'
import DropDown from './DropDown'
import CirlceStatistic from './CirlceStatistic'
export default function ProxyTest({ openModal }) {
  const valid = [
    { name: 'IP:PORT:USER:PASS', timing: '33' },
    { name: 'IP:PORT:USER:PASS', timing: '55' },
    { name: 'IP:PORT:USER:PASS', timing: '34' },
    { name: 'IP:PORT:USER:PASS', timing: '60' },
  ]
  const outtimed = [
    { name: 'IP:PORT:USER:PASS', timing: '333' },
    { name: 'IP:PORT:USER:PASS', timing: '655' },
  ]
  const validRate =
    valid && outtimed && ((valid.length * 100) / (valid.length + outtimed.length)).toFixed(0)
  const outtimedRate =
    valid &&
    outtimed &&
    ((outtimed.length.toFixed(1) * 100) / (valid.length + outtimed.length)).toFixed(0)
  return (
    <div className='flex  flex-col min-w-[228px] h-full  text-[11px]'>
      <span className='flex justify-between '>
        Test Proxies
        <button className='text-[10px] flex justify-between items-center text-dblue focus:outline-none'>
          Start Test
          <span className='transform -rotate-90 ml-2'>
            <ArrowDropDown />
          </span>
        </button>
      </span>
      <span className='h-0.5 w-full bg-dblack my-[11px] flex-shrink-0'></span>
      <div>
        <DropDown plchldr='Site' />
      </div>
      <div className=' flex flex-col bg-dgray px-5 py-[14px] my-3 rounded-[5px] h-full flex-shrinkb'>
        <h5 className='text-[10px] text-mgray pb-0.5'>Valid</h5>
        {valid.map((item, i) => (
          <span key={i} className='pb-1 text-[10px] leading-[12px]'>
            {item.name} <span className='text-dgreen'>{item.timing}ms</span>
          </span>
        ))}
        <h5 className='text-[10px] text-mgray mt-2 pb-0.5'>Outtimed</h5>
        {outtimed.map((item, i) => (
          <span key={i} className='pb-1 text-[10px] leading-[12px]'>
            {item.name} <span className='text-dbrown'>{item.timing}ms</span>
          </span>
        ))}
      </div>
      <div className='flex justify-between   bg-dgray px-5 py-[14px] mb-4 rounded-[5px] text-[10px] min-h-[114px]'>
        <div className='flex flex-col justify-between'>
          <div>
            <p className='text-mgray'>Test Statistic</p>
            <p className='text-[13px] text-wht'>{valid.length + outtimed.length} Proxies</p>
          </div>
          <div>
            <p className='text-mgray'>Tested on</p>
            <p className='text-[18px] bg-clip-text text-transparent bg-gradient-to-r from-[#526AE3] to-[#8CA9F3]'>
              Zalando
            </p>
          </div>
        </div>
        <div className='flex flex-col justify-evenly'>
          <CirlceStatistic progress={validRate} valid={true} />
          <CirlceStatistic progress={outtimedRate} valid={false} />
        </div>
      </div>
      <div className='flex justify-end flex-shrink-0 mt-3'>
        <button
          onClick={openModal}
          className='px-[30px] py-3 bg-dgray rounded-[5px] flex items-center justify-center text-dblue text-[10px] leading-[12px] hover:bg-lgray transition-colors'>
          Add Proxies
        </button>
      </div>
    </div>
  )
}
