import React from 'react'
import MasterCard from './icons/MasterCard'

export default function LoginProfileCard() {
  return (
    <div className='rounded-11 overflow-hidden flex flex-col min-h-[128px] text-[10px] bg-gradient-to-r from-[#526AE3] to-[#8CA9F3]'>
      <div className='w-full flex justify-center items-center bg-white backdrop-filter blur-lg bg-opacity-40'>
        <p className='text-[10px] leading-[12px] pt-[9px] pb-1.5 text-wht'>
          2 Checkouts on Footlocker
        </p>
      </div>

      <div className='flex flex-col  pt-[18px] px-6'>
        <div className='flex justify-between mb-5'>
          <img className='w-4 h-4' src='./icons/wifi.png' alt='nfc' />
          <MasterCard />
        </div>
        <div className='flex justify-between '>
          <span>2342 ****</span>
        </div>
        <div className='flex justify-between pb-1 '>
          <span>Max Mustermann</span>
          <span>12/23</span>
        </div>
      </div>
    </div>
  )
}
