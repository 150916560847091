import React, { useState } from 'react'
import CaptchaItem from './CaptchaItem'
import ProfileModal from './ProfileModal'

export default function CaptchaMain() {
  const [modal, showModal] = useState(false)
  const closeModal = (e) => {
    showModal(false)
  }
  return (
    <div className='flex flex-col h-full'>
      <h3 className='text-[11px] leading-[13px] '>Harvester</h3>
      <span className='h-0.5 flex w-full bg-dblack my-[11px]'></span>
      <div className='grid gridCaptcha gap-x-[22px] gap-y-[18px] mb-auto overflow-y-scroll scrl'>
        <CaptchaItem />
        <CaptchaItem />
      </div>

      {modal && (
        <div
          onClick={(e) => {
            if (e.target.classList[0] === 'cont') {
              closeModal()
            }
          }}
          className='cont z-10 fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center'>
          <ProfileModal closeModal={closeModal} />
        </div>
      )}
    </div>
  )
}
