import React, { useState } from 'react'
import Bubble from './Bubble'
import CheckMarkGreen from './icons/CheckMarkGreen'
import LoginProfileCard from './LoginProfileCard'
import LogoSection from './LogoSection'
import { useGlobal } from '../GlobalContext'
const bubbles = [
  { size: 60, top: `26%`, left: '23%' },
  { size: 26, top: `29%`, left: '41%' },
  { size: 60, top: `29%`, left: '71%' },
  { size: 60, top: `59%`, left: '73%' },
  { size: 60, top: `66%`, left: '60%' },
  { size: 26, top: `69%`, left: '34%' },
  { size: 60, top: `65%`, left: '24%' },
]
export default function Login() {
  const { setLoggedIn } = useGlobal()
  const [keyCheck, setKeyCheck] = useState(false)
  const handleCheck = () => {
    setKeyCheck(true)
    setTimeout(() => {
      setLoggedIn(true)
    }, 1000)
  }
  return (
    <div className='relative w-screen h-screen bg-gradient-to-r from-[#526AE3] to-[#8CA9F3] text-[12px] rounded-11 overflow-hidden'>
      <div className='fixed w-6 h-6 top-[8px] right-[8px]'>
        <button className='w-2 h-2 mx-0.5 bg-wht rounded-full'></button>
        <button className='w-2 h-2 mx-0.5 bg-wht rounded-full'></button>
      </div>
      <div className='absolute top-[15%] left-1/2 transform -translate-x-1/2'>
        <LogoSection />
      </div>
      <div className='absolute bottom-[5%] left-1/2 transform -translate-x-1/2'>
        Copyright 2021 RhydeAIO
      </div>
      {bubbles.map((bbl, i) => (
        <Bubble i={i} key={i} {...bbl} />
      ))}
      <div className='absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-h-[318px] min-w-[625px] glassed flex justify-between px-10 items-center'>
        {keyCheck ? (
          <div className='absolute centering top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-col'>
            <div className=''>Your Key is being verified</div>
            <div className='h-10 w-full flex justify-center my-2.5'>
              <div className='glassed move-left transition-all w-10 h-10 rounded-100 m-0.5'></div>
              <div className='glassed-blue move-right transition-all w-10 h-10 rounded-100 m-0.5'></div>
            </div>
          </div>
        ) : (
          <>
            <div className='max-w-1/2 min-w-[246px] flex flex-col'>
              <p>Hi,</p>
              <p className='pb-5'>Welcome to RhydeAIO!</p>
              <p className='pb-2 text-[11px]'>Please, enter your Key to continue</p>
              <input
                className='glassed placeholder-wht px-5 text-[11px] h-[35px] flex items-center mb-3 w-full'
                placeholder='XXXX-XXXX-XXXX-XXXX'
              />
              <button
                onClick={handleCheck}
                className='glassed py-2.5 px-5 hover:bg-gradient-to-r from-[#526AE3] to-[#8CA9F3] text-[12px] centering max-w-[120px] h-[35px] mb-3'>
                <span className='pr-2'>Login</span>
                <CheckMarkGreen />
              </button>
              <p className='text-[#E3E3E3] font-medium text-[10px]'>
                Need help?
                <a className='underline ' href='/'>
                  Click Here
                </a>
              </p>
            </div>
            <div className='min-w-[213px] max-w-1/2 '>
              <p className='flex justify-between'>
                Track your<span className='text-sgreen'>+12%</span>
              </p>
              <p className='text-lg leading-none mb-5'>Success</p>
              <LoginProfileCard />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
