import React from 'react'
import Delete from './icons/Delete'
import Edit from './icons/Edit'
import Play from './icons/Play'

export default function CaptchaItem() {
  return (
    <div className='rounded-11 overflow-hidden flex flex-col max-w-[224px] text-[10px] leading-[12px] bg-gradient-to-r from-[#526AE3] to-[#8CA9F3]'>
      <div className='flex flex-col  items-center pt-[18px] px-6'>
        <span className='capitalize'>Shopify checkpoint only</span>
        <span className='capitalize'>Recaptcha V3</span>
        <div className='h-2.5 w-20 flex justify-center my-2.5'>
          <div className='glassed move-left transition-all w-2.5 h-2.5 rounded-full m-0.5'></div>
          <div className='glassed-blue move-right transition-all w-2.5 h-2.5 rounded-full m-0.5'></div>
        </div>
        <span className='capitalize pb-3'>Waiting for captcha...</span>
        <div className='flex justify-between'>
          <div className='flex pb-2.5'>
            <button className='w-[22px] h-[22px] rounded-100 glassed flex items-center justify-center mr-1.5 focus:outline-none'>
              <Delete />
            </button>
            <button
              className={`w-[23px] h-[23px] rounded-100  glassed-blue flex items-center justify-center mr-1.5 focus:outline-none`}>
              <Edit />
            </button>
            <button
              className={`w-[22px] h-[22px] rounded-100  glassed flex items-center justify-center focus:outline-none pl-px`}>
              <Play />
            </button>
          </div>
        </div>
        <span className='capitalize pb-2.5 text-sgreen'>Opened</span>
      </div>
      <div className='w-full flex justify-center items-center bg-white backdrop-filter blur-lg bg-opacity-50'>
        <p className='text-[10px] leading-[12px] pt-[9px] pb-1.5 text-[#555]'>Harvester 1</p>
      </div>
    </div>
  )
}
