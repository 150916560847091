import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'
import ArrowDropDown from './icons/ArrowDropDown'

const people = [
  { name: 'Amazon' },
  { name: '6pm' },
  { name: 'Adidas' },
  { name: 'Nike' },
  { name: 'HBX' },
  { name: 'FARFETCH' },
  { name: 'Amazon' },
  { name: '6pm' },
  { name: 'Adidas' },
  { name: 'Nike' },
  { name: 'HBX' },
  { name: 'FARFETCH' },
]

export default function Example({ plchldr = '' }) {
  const [selected, setSelected] = useState()

  return (
    <div className='text-wht'>
      <Listbox value={selected} onChange={setSelected}>
        <div className='relative '>
          <Listbox.Button className='relative w-full h-9 flex items-center py-1 px-5 text-left bg-dgray rounded-[5px]  cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm'>
            {selected ? (
              <span className='block truncate text-[10px] leading-none'>{selected.name}</span>
            ) : (
              <span className='block truncate text-[10px] leading-none text-[#555]'>{plchldr}</span>
            )}
            <span className='absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none'>
              <ArrowDropDown />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Listbox.Options className='absolute w-full py-1 mt-1 overflow-auto text-[10px] leading-none  z-[2]  bg-dgray rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none scrl '>
              {people.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `${active ? ' bg-lgray' : ''}
                          cursor-pointer select-none relative py-2 pl-10 pr-4`
                  }
                  value={person}>
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>
                        {person.name}
                      </span>
                      {selected ? (
                        <span
                          className={`${active ? 'text-amber-600' : 'text-amber-600'}
                                absolute inset-y-0 left-0 flex items-center pl-3`}>
                          <CheckIcon className='w-5 h-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
