import React from 'react'
import DropDown from './DropDown'
export default function CreateHarvester() {
  return (
    <div className='flex flex-col bg-dg px-6 py-4 pb-5 rounded-11'>
      <div className='flex justify-between pr-6'>
        <span className='text-xs '>Create Harvester</span>
        <span onClick={() => {}} className='text-[10px] add-plus-icon text-dblue cursor-pointer'>
          Add
        </span>
      </div>
      <div className='w-full flex-shrink-0 flex pr-6 '>
        <span className='h-0.5 w-full bg-dblack my-[11px]'></span>
      </div>
      <div className='flex flex-col '>
        <div className='mb-2.5'>
          <DropDown plchldr='Name' />
        </div>
        <div className='mb-2.5'>
          <DropDown plchldr='Site (set to all)' />
        </div>
        <div className='mb-2.5'>
          <DropDown plchldr='Harvester type' />
        </div>
        <div className='mb-2.5'>
          <DropDown plchldr='Localhost' />
        </div>
      </div>
    </div>
  )
}
