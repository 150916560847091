import React, { createContext, useContext, useState } from 'react'

const monitorsGroups = [
  {
    id: 101,
    name: 'Monitors Group 1',
    monitorsArr: [
      {
        id: 101,
        site: 'Footlocker US',
        product: 'Nike Dunk SB Royal Blue',
        host: 'Localhost',
        tasks: '17 Tasks',
        profile: 'Profile 1',
      },
      {
        id: 102,
        site: 'Footlocker US',
        product: 'Nike Dunk SB Royal Blue',
        host: 'Localhost',
        tasks: '17 Tasks',
        profile: 'Profile 1',
      },
      {
        id: 103,
        site: 'Footlocker US',
        product: 'Nike Dunk SB Royal Blue',
        host: 'Localhost',
        tasks: '17 Tasks',
        profile: 'Profile 1',
      },
    ],
  },
  {
    id: 102,
    name: 'Monitors Group 2',
    monitorsArr: [
      {
        id: 101,
        site: 'Kith NYC',
        product: 'Nike Dunk SB Red',
        host: 'Localhost',
        tasks: '17 Tasks',
        profile: 'Profile 1',
      },
      {
        id: 102,
        site: 'Kith NYC',
        product: 'Nike Dunk SB Red',
        host: 'Localhost',
        tasks: '17 Tasks',
        profile: 'Profile 1',
      },
      {
        id: 103,
        site: 'Kith NYC',
        product: 'Nike Dunk SB Red',
        host: 'Localhost',
        tasks: '17 Tasks',
        profile: 'Profile 1',
      },
    ],
  },
]
const profGr = [
  {
    id: 1011,
    name: 'Profile Group 1',
    profilesArr: [
      {
        id: 10101,
        name: 'Profile 1',
        card: '23214978214867',
        key: '441',
        dueDate: '12/23',
        cardholder: 'Max Mustermann',
      },
      {
        id: 10102,
        name: 'Profile 2',
        card: '43214978214867',
        key: '440',
        dueDate: '12/23',
        cardholder: 'Max Mustermann',
      },
      {
        id: 10102,
        name: 'Profile 3',
        card: '23421497821486',
        key: '442',
        dueDate: '12/23',
        cardholder: 'Max Mustermann',
      },
    ],
  },
  {
    id: 1012,
    name: 'Profile Group 2',

    profilesArr: [
      {
        id: 10101,
        name: 'Profile 11',
        card: '23214978214867',
        key: '441',
        dueDate: '12/23',
        cardholder: 'Max Mustermann',
      },
      {
        id: 10102,
        name: 'Profile 22',
        card: '43214978214867',
        key: '440',
        dueDate: '12/23',
        cardholder: 'Max Mustermann',
      },
      {
        id: 10102,
        name: 'Profile 33',
        card: '23421497821486',
        key: '442',
        dueDate: '12/23',
        cardholder: 'Max Mustermann',
      },
    ],
  },
]
const proxGroups = [
  {
    id: 20001,
    name: 'Proxy Group 1',
    proxiesArr: [
      {
        id: 1,
        url: '192.168.1.1:9999:BRUH:PSSWRD',
      },
      {
        id: 2,
        url: '192.168.1.1:9999:BRUH:PSSWRD',
      },
      {
        id: 3,
        url: '192.168.1.1:9999:BRUH:PSSWRD',
      },
    ],
  },
  {
    id: 20002,
    name: 'Proxy Group 2',
    proxiesArr: [
      {
        id: 1,
        url: '192.168.1.1:9999:BRUH:PSSWRD',
      },
      {
        id: 2,
        url: '192.168.1.1:9999:BRUH:PSSWRD',
      },
      {
        id: 3,
        url: '192.168.1.1:9999:BRUH:PSSWRD',
      },
      {
        id: 43,
        url: '192.168.1.1:9999:BRUH:PSSWRD',
      },
    ],
  },
]

export const globalContext = createContext()

export const useGlobal = () => {
  return useContext(globalContext)
}

export const GlobalProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false)

  const [tasks, setTasks] = useState([
    {
      name: 'Task Group 1',
      id: 1,
      taskArr: [
        {
          id: 'A1',
          profile: 'Profile 1',
          site: 'Footlocker US',
          product: 'Nike SB Dunk Low Pro Blue',
          sizes: [7, 8, 9],
          proxy: 'Proxy List 1',
        },
        {
          id: 'A1',
          profile: 'Profile 1',
          site: 'Footlocker US',
          product: 'Nike SB Dunk Low Pro Blue',
          sizes: [7, 8, 9],
          proxy: 'Proxy List 1',
        },
      ],
    },
    {
      name: 'Task Group 2',
      id: 2,
      taskArr: [
        {
          id: 'A1',
          profile: 'Profile 1',
          site: 'Footlocker US',
          product: 'Nike SB Dunk Low Pro Red',
          sizes: [7, 9],
          proxy: 'Proxy List 2',
        },
        {
          id: 'A1',
          profile: 'Profile 1',
          site: 'Footlocker US',
          product: 'Nike SB Dunk Low Pro Red',
          sizes: [8, 9],
          proxy: 'Proxy List 2',
        },
      ],
    },
  ])
  const [activeTaskGr, setActiveTaskGr] = useState(tasks[0])
  const [monitGroups, setMonitGroups] = useState(monitorsGroups)
  const [activeMonitGr, setActiveMonitGr] = useState(monitGroups[0])
  const [profileGroups, setProfileGroups] = useState(profGr)
  const [activeProfileGr, setActiveProfileGr] = useState(profileGroups[0])
  const [proxiesGroups, setProxiesGroups] = useState(proxGroups)
  const [activeProxiesGr, setActiveProxiesGr] = useState(proxiesGroups[0])
  return (
    <globalContext.Provider
      value={{
        activeTaskGr,
        setActiveTaskGr,
        loggedIn,
        setLoggedIn,
        tasks,
        setTasks,
        monitGroups,
        setMonitGroups,
        activeMonitGr,
        setActiveMonitGr,
        profileGroups,
        setProfileGroups,
        activeProfileGr,
        setActiveProfileGr,
        proxiesGroups,
        setProxiesGroups,
        activeProxiesGr,
        setActiveProxiesGr,
      }}>
      {children}
    </globalContext.Provider>
  )
}
