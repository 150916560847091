import React from 'react'
import MasterCardBlue from './icons/MasterCardBlue'

export default function DashboardLatestItem() {
  const cardnumber = `1234123412341234`
  return (
    <div className='w-full flex flex-col'>
      <div className='flex justify-between items-center py-[14px]'>
        <div className='flex flex-col'>
          <p className='text-[11px] leading-[13px]'>Footloker US</p>
          <p>
            {cardnumber.match(/.{1,4}/g).map((piece, i) => (
              <span className='text-[10px] text-sgray' key={i}>{`${piece} `}</span>
            ))}
          </p>
        </div>
        <div className='flex centering'>
          <p className='text-[11px] text-dblue pr-1.5'>180$</p>
          <MasterCardBlue />
        </div>
      </div>
      <span className='h-0.5 w-full bg-dblack'></span>
    </div>
  )
}
