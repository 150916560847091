import React from 'react'

export default function Start({ color = '#555' }) {
  return (
    <div
      style={{ border: `4px solid ${color}` }}
      className='w-4 h-4 rounded-sm flex justify-center items-center relative overflow-hidden '>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className=' absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-6 w-6'
        viewBox='0 0 20 20'
        fill={color}>
        <path
          fillRule='evenodd'
          d='M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  )
}
