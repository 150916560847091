import React, { useState } from 'react'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar } from 'react-modern-calendar-datepicker'
import DashboardCalendarItems from './DashboardCalendarItems'
export default function DashboardCalendar() {
  const [selectedDay, setSelectedDay] = useState(null)
  return (
    <div className='w-full h-[calc(100%-50px)] overflow-hidden flex flex-col flex-shrink  mt-2.5 pb-4'>
      <div className='flex-shrink-0 flex-grow-0 w-full h-[240px]'>
        <Calendar
          customDaysClassName={[
            // here we add some CSS classes
            { year: 2021, month: 6, day: 4, className: 'brownDay' },
            { year: 2021, month: 6, day: 1, className: 'greenDay' },
            { year: 2021, month: 6, day: 12, className: 'brownDay' },
            { year: 2021, month: 6, day: 16, className: 'greenDay' },
          ]}
          calendarClassName='calendar'
          value={selectedDay}
          onChange={setSelectedDay}
        />
      </div>
      <DashboardCalendarItems />
    </div>
  )
}
