import { useState } from 'react'
import SideBar from './Components/SideBar'
import LogoSection from './Components/LogoSection'
import Header from './Components/Header'
import Notify from './Components/Notify'
import MainWindow from './Components/MainWindow'
import SideBarHeader from './Components/SideBarHeader'
import Bubbles from './Components/Bubbles'
import SessionPixel from './Components/SessionPixel'
import { SessionTimeProvider } from './SessionTimeContext'
export default function Interface() {
  const [current, setCurrent] = useState('tasks')
  return (
    <SessionTimeProvider>
      <div className=' rounded-11 w-screen h-screen min-w-[1050px] min-h-[720px] rounded-11 overflow-hidden centering '>
        <div className=' bg-dblack h-screen w-screen  mx-auto fixed  rounded-11 overflow-hidden'>
          <Bubbles />
        </div>
        <div className=' w-full h-full  min-h-[634px]  max-h-[88vh] min-w-[1045px] max-w-[90vw] mx-auto z-10'>
          <div className='flex w-full'>
            <div className='flex-shrink-0 min-w-[245px]'>
              <SideBarHeader current={current} />
            </div>
            <div className='flex items-center ml-6 mb-9 w-full'>
              <LogoSection />
              <Header current={current} setCurrent={setCurrent} />
              <Notify />
            </div>
          </div>

          {current !== 'settings' ? (
            <div className='w-full h-full max-h-[76vh]  flex pb-5 '>
              <div className='min-w-[245px]  min-h-[550px] flex-shrink-0 h-full overflow-hidden  '>
                <SideBar current={current} />
              </div>
              <div className='w-full h-full ml-6  min-h-[550px] '>
                <MainWindow current={current} />
              </div>
            </div>
          ) : (
            <div className='w-full h-full  flex  relative z-10  min-h-[550px]'>
              <SideBar current={current} />
            </div>
          )}
        </div>

        <SessionPixel />
      </div>
    </SessionTimeProvider>
  )
}
