import React from 'react'
import CaptchaMain from './CaptchaMain'
import DashboardMain from './DashboardMain'
import MonitorsMain from './MonitorsMain'
import ProfilesMain from './ProfilesMain'
import ProxyMain from './ProxyMain'
import TasksTable from './TasksTable'
export default function MainWindow({ current }) {
  const mainItems = [
    { id: 'dashboard', component: DashboardMain },
    { id: 'tasks', component: TasksTable },
    { id: 'search', component: MonitorsMain },
    { id: 'profiles', component: ProfilesMain },
    { id: 'proxies', component: ProxyMain },
    { id: 'captcha', component: CaptchaMain },
    { id: 'settings', component: null },
  ]
  return mainItems.map((item) => {
    if (item.id === current && item.component !== null) {
      return (
        <div key={item.id} className='bg-dg rounded-11 w-full h-full px-6 py-[22px] min-w-[774px]'>
          <item.component />
        </div>
      )
    }
    if (item.id === current) {
      return <div className=' justify-center items-center'>this page is under construction... </div>
    }
    return null
  })
}
