import React from 'react'

export default function NotifyItem() {
  return (
    <button
      onMouseDown={() => {
        console.log('clicked on notify item')
      }}
      className='w-full h-full mb-2.5 flex flex-col relative p-3 bg-dblack  rounded-md text-sgray text-[10px] leading-[12px] focus:outline-none'>
      <div className='w-8 h-8 absolute centering top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        <img className='object-cover w-full h-auto' src='./icons/sneaker.png' alt='sneaker' />
      </div>
      <div className='flex w-full justify-between pb-2 border-b-2 border-dg'>
        <p className='capitalize text-dgreen'> 1 checkout</p>
        <p className='uppercase '> us8 / 180$</p>
      </div>
      <div className='flex w-full justify-between pt-2'>
        <p className='capitalize '> Footlocker US</p>
        <p className=' '> 36m ago</p>
      </div>
    </button>
  )
}
