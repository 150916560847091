import React from 'react'

export default function AnnouncmentModal({ closeModal, author, subj, text }) {
  return (
    <div className='w-1/3 h-1/3 min-w-[286px] min-h-[238px] bg-dg py-4 px-[30px] flex flex-col rounded-[5px]'>
      <div className='flex items-center text-[10px] border-b-2 pb-2 border-dblack flex-shrink-0 mb-2.5 '>
        <div className='mr-auto flex flex-col '>
          <h3 className=' text-[#777]'>{author}</h3>
          <h5 className='text-wht'>{subj}</h5>
        </div>
        <img src='./icons/Daco.png' alt='admin' />
      </div>
      <p className='h-[calc(100%-72px)] overflow-y-scroll scrl flex-shrink '>{text}</p>
      <button
        onClick={closeModal}
        className='close mt-3  text-[10px] leading-none h-5 self-center flex-shrink-0  text-dblue  px-[30px] '>
        Close
      </button>
    </div>
  )
}
