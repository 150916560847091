import React, { createContext, useContext, useState } from 'react'

export const SessionTimeContext = createContext()

export const useSession = () => {
  return useContext(SessionTimeContext)
}

export const SessionTimeProvider = ({ children }) => {
  const [sessionTime, setTime] = useState('')

  return (
    <SessionTimeContext.Provider
      value={{
        sessionTime,
        setTime,
      }}>
      {children}
    </SessionTimeContext.Provider>
  )
}
