import React from 'react'
import Category from './icons/Category'
import Search from './icons/Search'
import Tasks from './icons/Tasks'
import Tune from './icons/Tune'
import Updates from './icons/Updates'
import Settings from './icons/Settings'
import Wallet from './icons/Wallet'
export default function Header({ current, setCurrent }) {
  const navItems = [
    { id: 'dashboard', component: Category },
    { id: 'tasks', component: Tasks },
    { id: 'search', component: Search },
    { id: 'profiles', component: Wallet },
    { id: 'proxies', component: Tune },
    { id: 'captcha', component: Updates },
    { id: 'settings', component: Settings },
  ]
  return (
    <div className='glassed flex w-full max-w-[515px] ml-auto h-10 items-center px-6 '>
      <div className='w-[286px] flex items-center justify-between'>
        {navItems.map((item) => {
          const color = item.id === current ? '#19297D' : '#fff'
          return (
            <div
              className='cursor-pointer relative w-5 h-5 flex justify-center items-center'
              key={item.id}
              onClick={() => setCurrent(item.id)}>
              <item.component color={color} />
              {item.id === current && (
                <div className='absolute -bottom-2.5 w-full h-0.5 bg-[#0057FF]'></div>
              )}
            </div>
          )
        })}
      </div>

      <button className='flex items-center mr-auto'>
        <div className='h-5 w-0.5 bg-[#A2B1E1] ml-[30px] mr-[18px]'></div>
        <img src='./icons/Daco.png' alt='avatar' />
        <span className='text-[10px] ml-4'>Username</span>
      </button>
    </div>
  )
}
