import React from 'react'

export default function Wallet({ color = '#fff' }) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.8072 6.98483H18.3332C18.3332 4.15383 16.6369 2.5 13.7628 2.5H6.23687C3.3628 2.5 1.6665 4.15383 1.6665 6.94872V13.0513C1.6665 15.8462 3.3628 17.5 6.23687 17.5H13.7628C16.6369 17.5 18.3332 15.8462 18.3332 13.0513V12.7913H14.8072C13.1708 12.7913 11.8443 11.4979 11.8443 9.9025C11.8443 8.30707 13.1708 7.01372 14.8072 7.01372V6.98483ZM14.8072 8.22699H17.711C18.0546 8.22699 18.3332 8.49859 18.3332 8.83363V10.9424C18.3292 11.2759 18.0529 11.5452 17.711 11.5491H14.8739C14.0455 11.56 13.3211 11.007 13.1332 10.2202C13.0391 9.73189 13.1712 9.22795 13.4941 8.84349C13.817 8.45903 14.2976 8.23337 14.8072 8.22699ZM14.9332 10.4441H15.2073C15.5591 10.4441 15.8443 10.1661 15.8443 9.82304C15.8443 9.48002 15.5591 9.20195 15.2073 9.20195H14.9332C14.7649 9.20002 14.6029 9.26384 14.4832 9.37917C14.3635 9.4945 14.2961 9.65175 14.2961 9.81581C14.2961 10.16 14.5802 10.4402 14.9332 10.4441ZM5.61464 6.98485H10.3183C10.6702 6.98485 10.9554 6.70678 10.9554 6.36376C10.9554 6.02074 10.6702 5.74267 10.3183 5.74267H5.61464C5.26569 5.74265 4.98166 6.01634 4.97761 6.35654C4.97758 6.70074 5.26162 6.98089 5.61464 6.98485Z'
        fill={color}
      />
    </svg>
  )
}
