import React from 'react'

export default function ArrowDropDown() {
  return (
    <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.246 7.08703C3.20733 7.04936 3.042 6.90713 2.906 6.77464C2.05067 5.99789 0.650667 3.97159 0.223333 2.91102C0.154667 2.74996 0.00933333 2.34275 0 2.12518C0 1.91671 0.048 1.71797 0.145333 1.52833C0.281333 1.29193 0.495333 1.10229 0.748 0.998376C0.923333 0.931482 1.448 0.827569 1.45733 0.827569C2.03133 0.723656 2.964 0.666504 3.99467 0.666504C4.97667 0.666504 5.87133 0.723656 6.454 0.808735C6.46333 0.818477 7.11533 0.92239 7.33867 1.03604C7.74667 1.24452 8 1.65173 8 2.08751V2.12518C7.99 2.40899 7.73667 3.00584 7.72733 3.00584C7.29933 4.00925 5.968 5.9888 5.08333 6.78438C5.08333 6.78438 4.856 7.00844 4.714 7.10586C4.51 7.25783 4.25733 7.33317 4.00467 7.33317C3.72267 7.33317 3.46 7.24809 3.246 7.08703Z'
        fill='#8CA9F3'
      />
    </svg>
  )
}
