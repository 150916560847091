import React, { useState } from 'react'
import DashboardCalendar from './DashboardCalendar'
import DashboardLatest from './DashboardLatest'
import NextPrevButton from './NextPrevButton'

export default function DashboardSidebar() {
  const [next, turnNext] = useState(true)
  return (
    <div className=' min-h-[550px] h-full bg-dg  max-w-[245px] pb-2.5 '>
      <div className='flex flex-col px-6 min-h-[48px] flex-shrink-0 justify-center box-border'>
        <div className='flex justify-between items-center text-[11px] my-auto '>
          <div className='w-4 h-4 centering'>
            <button
              disabled={!next}
              onClick={() => turnNext(false)}
              className={next ? 'focus:outline-none' : 'hidden focus:outline-none'}>
              <NextPrevButton rotate />
            </button>
          </div>
          <div>{next ? 'Latest Spendings' : 'Latest Spendings'}</div>
          <div className='w-4 h-4 centering'>
            <button
              disabled={next}
              onClick={() => turnNext(true)}
              className={!next ? 'focus:outline-none' : 'hidden focus:outline-none'}>
              <NextPrevButton />
            </button>
          </div>
        </div>
        <span className='h-0.5 w-full flex flex-shrink-0 bg-dblack  '></span>
      </div>
      {next ? <DashboardCalendar /> : <DashboardLatest />}
    </div>
  )
}
