import React from 'react'
import SessionTimer from './SessionTimer'
import SettingsInputs from './SettingsInputs'
import SettingsLicense from './SettingsLicense'
import SettingsLicenseType from './SettingsLicenseType'

export default function Settings() {
  const license_key = `123456789012`
  return (
    <div className='w-full h-full  text-[11px] leading-[13px]'>
      <div className='w-full flex flex-col bg-dg pt-4 px-[30px] rounded-md'>
        <h3 className=''>Settings Overview</h3>
        <span className='h-0.5 w-full bg-dblack my-[11px] mb-5'></span>
        <div className='grid grid-cols-2 w-full gap-x-4 mb-10'>
          <div className='flex flex-col w-full h-full '>
            <div className='w-full h-[50px] rounded-md bg-dgray flex items-center pl-3 pr-5 mb-2.5 flex-shrink-0'>
              <div className='w-[30px] h-[30px] rounded-sm flex items-center justify-center bg-[#555] mr-3'>
                <img src='./icons/Daco.png' alt='avatar' />
              </div>
              <SettingsLicense license_key={license_key} />
              <div className='h-full flex items-center ml-auto'>
                <button className='text-dblue'>Copy</button>
                <button className='text-dbrown ml-3'>Log Out</button>
              </div>
            </div>
            <div className='h-full flex-shrink'>
              <SettingsLicenseType />
            </div>
          </div>
          <SettingsInputs />
        </div>
      </div>
      <div className='w-full  min-h-[200px] flex-shrink mt-5 bg-dg rounded-md flex justify-center items-center'>
        <SessionTimer />
      </div>
    </div>
  )
}
