import React from 'react'

export default function DashboardCalendarItems({ items }) {
  const itms = [
    { id: 1, name: 'FootlockerUS', time: '4:00PM', sku: 'SKU' },
    { id: 2, name: 'FootlockerUS', time: '4:00PM', sku: 'SKU' },
    { id: 3, name: 'FootlockerUS', time: '4:00PM', sku: 'SKU' },
    { id: 4, name: 'FootlockerUS', time: '4:00PM', sku: 'SKU' },
    { id: 35, name: 'FootlockerUS', time: '4:00PM', sku: 'SKU' },
    { id: 32, name: 'FootlockerUS', time: '4:00PM', sku: 'SKU' },
    { id: 31, name: 'FootlockerUS', time: '4:00PM', sku: 'SKU' },
    { id: 23, name: 'FootlockerUS', time: '4:00PM', sku: 'SKU' },
    { id: 33, name: 'FootlockerUS', time: '4:00PM', sku: 'SKU' },
    { id: 63, name: 'FootlockerUS', time: '4:00PM', sku: 'SKU' },
  ]
  return (
    <div className='h-full max-w-[245px] pl-6 overflow-y-scroll scrl pr-5  mt-2.5 '>
      <h3 className='bg-dg sticky top-0 flex justify-between text-[11px] leading-[13px] pb-2.5 items-center'>
        <p className=' max-w-[70%] pb-1  overflow-ellipsis'>Nike SB Dunk Low Pro Blue Fury</p>
        <img
          className='object-cover w-9 h-auto'
          src='./icons/sneaker.png'
          alt='Nike Sb Dunk Pro Blue'
        />
      </h3>
      <div className='h-[calc(100%-36px)]   '>
        <div className='h-full flex flex-col'>
          {itms.map((item, i) => (
            <div
              key={item.id}
              className={`bg-dgray rounded-md px-[15px] py-2.5 flex justify-between items-center ${
                i === itms.length - 1 ? '' : 'mb-2.5'
              } `}>
              <div className='flex flex-col '>
                <p className='text-[11px] text-wht'>{item.name}</p>
                <p className='text-[10px] flex justify-between '>
                  <span className='text-sgray'>{item.time}</span>
                  <span className='text-sgreen'>{item.sku}</span>
                </p>
              </div>
              <div>
                <img src='./icons/newWindow.png' alt='new window' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
