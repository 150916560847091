import React, { useState } from 'react'
import Refresh from './icons/Refresh'
import Play from './icons/Play'

export default function TaskTableItem({ task }) {
  const [refresh, startRefresh] = useState(false)
  const startTask = () => {
    startRefresh(true)
  }
  return (
    <tr className='w-full pl-[18px] pr-[16px] py-2.5 bg-dgray h-[35px]   rounded-[5px] mb-3 text-[10px] leading-[12px] '>
      <td className='text-[#555]'>{task.id}</td>
      <td className=']'>{task.site}</td>
      <td className=']'>{task.product}</td>
      <td className=''>{task.profile}</td>
      <td className=' text-dblue'>
        {task.sizes.map((s, i) => (
          <span key={i}>
            {s}
            {i < task.sizes.length - 1 && ','}
          </span>
        ))}
      </td>
      <td className=''>{task.proxy}</td>
      <td className='text-dgreen'>Checkout Success</td>
      <td className=''>
        {refresh ? (
          <button className={`w-4 h-4  flex items-center justify-center focus:outline-none`}>
            <Refresh />
          </button>
        ) : (
          <button
            onClick={startTask}
            className={`w-4 h-4 rounded-sm bg-sgray flex items-center justify-center focus:outline-none`}>
            <Play color='black' width={8} height={8} />
          </button>
        )}
      </td>
    </tr>
  )
}
