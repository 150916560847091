import React from 'react'
import Bubble from './Bubble'
function Bubbles() {
  const bubbles = [
    { size: 60, top: `10%`, left: '3%' },
    { size: 26, top: `20%`, left: `21%` },
    { size: 60, top: `13%`, left: `43%` },
    { size: 26, top: `45%`, left: `55%` },
    { size: 60, top: `35%`, left: `78%` },
    { size: 60, top: `-15%`, left: `94%` },
  ]
  return (
    <div className='w-full min-h-[156px] h-1/6 bg-gradient-to-r from-[#526AE3] to-[#8CA9F3] flex relative'>
      {bubbles.map((bbl, i) => (
        <Bubble i={i} key={i} {...bbl} />
      ))}
      <div className='fixed w-6 h-6 top-[3px] right-[8px]'>
        <button className='w-2 h-2 mx-0.5 bg-wht rounded-full'></button>
        <button className='w-2 h-2 mx-0.5 bg-wht rounded-full'></button>
      </div>
    </div>
  )
}
export default React.memo(Bubbles)
