import React from 'react'

export default function Search({ color = '#fff' }) {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.666504 7.89144C0.666504 3.90122 3.86679 0.666504 7.81454 0.666504C9.71031 0.666504 11.5284 1.4277 12.869 2.78264C14.2095 4.13758 14.9626 5.97527 14.9626 7.89144C14.9626 11.8817 11.7623 15.1164 7.81454 15.1164C3.86679 15.1164 0.666504 11.8817 0.666504 7.89144ZM14.8443 13.7118L16.9732 15.4302H17.0101C17.4408 15.8655 17.4408 16.5714 17.0101 17.0067C16.5794 17.442 15.8811 17.442 15.4504 17.0067L13.6838 14.9819C13.5167 14.8137 13.4229 14.5852 13.4229 14.3468C13.4229 14.1085 13.5167 13.88 13.6838 13.7118C14.0059 13.3918 14.5222 13.3918 14.8443 13.7118Z'
        fill={color}
      />
    </svg>
  )
}
