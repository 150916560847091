import React from 'react'
import CaptchaSideBar from './CaptchaSideBar'
import DashboardSidebar from './DashboardSidebar'
import MonitorsSideBar from './MonitorsSideBar'
import ProfilesSideBar from './ProfilesSideBar'
import ProxySideBar from './ProxySideBar'
import Settings from './Settings'
import TaskSideBar from './TaskSideBar'

export default function SideBar({ current }) {
  const sideBarItems = [
    {
      id: 'dashboard',
      component: DashboardSidebar,
      heading: 'Hi, UserName',
      text: 'Welcome back to RhydeAIO!',
    },
    { id: 'tasks', component: TaskSideBar, heading: 'Tasks' },
    { id: 'search', component: MonitorsSideBar, heading: 'Monitors' },
    { id: 'profiles', component: ProfilesSideBar, heading: 'Profiles' },
    { id: 'proxies', component: ProxySideBar, heading: 'Proxies' },
    { id: 'captcha', component: CaptchaSideBar, heading: 'Captcha' },
    { id: 'settings', component: Settings, heading: 'Settings' },
  ]
  return (
    <div className='min-w-[245px] w-full flex-shrink-0 relative flex flex-col h-full'>
      <div className='overflow-hidden rounded-11 relative  min-h-[550px] h-full z-10'>
        {sideBarItems.map((item) => {
          if (item.id === current && item.component !== null)
            return <item.component key={item.id} />
          if (item.id === current) {
            return (
              <div className='w-full h-full justify-center items-center px-4 py-4'>
                Under construction...
              </div>
            )
          }
          return null
        })}
      </div>
    </div>
  )
}
