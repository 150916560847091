import React from 'react'

export default function Category({ color = '#fff' }) {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.78194 0.666504H5.60358C6.77214 0.666504 7.71902 1.62138 7.71902 2.79981V5.64527C7.71902 6.8237 6.77214 7.77858 5.60358 7.77858H2.78194C1.61338 7.77858 0.666504 6.8237 0.666504 5.64527V2.79981C0.666504 1.62138 1.61338 0.666504 2.78194 0.666504Z'
        fill={color}
      />
      <path
        d='M2.78194 10.2212H5.60358C6.77214 10.2212 7.71902 11.1761 7.71902 12.3545V15.2C7.71902 16.3776 6.77214 17.3333 5.60358 17.3333H2.78194C1.61338 17.3333 0.666504 16.3776 0.666504 15.2V12.3545C0.666504 11.1761 1.61338 10.2212 2.78194 10.2212Z'
        fill={color}
      />
      <path
        d='M12.3962 10.2212H15.2178C16.3864 10.2212 17.3333 11.1761 17.3333 12.3545V15.2C17.3333 16.3776 16.3864 17.3333 15.2178 17.3333H12.3962C11.2276 17.3333 10.2808 16.3776 10.2808 15.2V12.3545C10.2808 11.1761 11.2276 10.2212 12.3962 10.2212Z'
        fill={color}
      />
      <path
        d='M12.3962 0.666504H15.2178C16.3864 0.666504 17.3333 1.62138 17.3333 2.79981V5.64527C17.3333 6.8237 16.3864 7.77858 15.2178 7.77858H12.3962C11.2276 7.77858 10.2808 6.8237 10.2808 5.64527V2.79981C10.2808 1.62138 11.2276 0.666504 12.3962 0.666504Z'
        fill={color}
      />
    </svg>
  )
}
