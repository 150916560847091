import React, { useState, useRef, useEffect } from 'react'
import NotifyModal from './NotifyModal'
export default function Notify() {
  const mainRef = useRef(null)

  useEffect(() => {
    mainRef.current.focus()
  }, [mainRef])
  const [fullAnnou, showFullAnnou] = useState(false)
  const closeModal = (e) => {
    showFullAnnou(false)
  }
  const openModal = (e) => {
    showFullAnnou(true)
  }
  return (
    <div>
      <div ref={mainRef} tabIndex='-1' onBlur={closeModal} className='relative '>
        <button
          onClick={openModal}
          className='glassed notify transition-all cursor-pointer  w-10 h-10 ml-[14px] flex items-center justify-center focus:outline-none'>
          <img src='./icons/notification.svg' alt='notifications' />
        </button>
        {fullAnnou && (
          <div
            // onClick={closeModal}
            className='close z-20 absolute top-[48px] -right-2 w-[286px] max-h-[373px]   flex justify-center items-center'>
            <NotifyModal closeModal={closeModal} />
          </div>
        )}
      </div>
    </div>
  )
}
