import React from 'react'

export default function TaskButton({ children, addClass }) {
  return (
    <button
      className={`${addClass} bg-dgray w-9 h-9 rounded-[5px] flex items-center justify-center  hover:bg-lgray transition-colors`}>
      {children}
    </button>
  )
}
