import React from 'react'
import { useSession } from '../SessionTimeContext'

export default function SessionTimer() {
  const { sessionTime } = useSession()

  return (
    <div className='w-[150px]'>
      <h2>Current Session</h2>
      <h3 className='text-4xl text-dblue'>{sessionTime}</h3>
    </div>
  )
}
