import React from 'react'
import Delete from './icons/Delete'
import Start from './icons/Start'

export default function ProxyMainItem({ data }) {
  return (
    <div className='w-full pl-[18px] pr-[16px] py-2.5 bg-dgray h-9  flex items-center rounded-[5px] mb-3 text-[10px] leading-[12px] '>
      <div className='w-8 text-[#555]'>{data.id}</div>
      <div className='w-auto uppercase mr-auto'>{data.url}</div>

      <div className='flex items-center'>
        <button className='w-5 h-5 flex items-center justify-center focus:outline-none mr-2'>
          <Delete color={'#555'} width='14' height='14' />
        </button>
        <button className='focus:outline-none'>
          <Start />
        </button>
      </div>
    </div>
  )
}
