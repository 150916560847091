import React, { useState } from 'react'
import APIKeys from './APIKeys'
import CreateHarvester from './CreateHarvester'
import TaskGroupModal from './TaskGroupModal'

export default function CaptchaSideBar() {
  const [modal, showModal] = useState(false)
  const closeModal = (e) => {
    showModal(false)
  }
  return (
    <div className='flex flex-col   min-h-[550px] h-full'>
      <div className='flex flex-col h-full justify-between'>
        <CreateHarvester />
        <APIKeys />
      </div>
      {modal && (
        <div
          onClick={(e) => {
            if (e.target.classList[0] === 'cont') {
              closeModal()
            }
          }}
          className='cont z-10 fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center'>
          <TaskGroupModal closeModal={closeModal} />
        </div>
      )}
    </div>
  )
}
