import React, { useState } from 'react'
import AnnouncmentModal from './AnnouncmentModal'

export default function AnnouncmentItem({
  author = 'Admin#0001',
  subj = 'Some Subject',
  text = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
}) {
  const [fullAnnou, showFullAnnou] = useState(false)
  const closeModal = (e) => {
    e.target.classList.value.split(' ').some((cls) => cls === 'close') && showFullAnnou(false)
  }
  const openModal = (e) => {
    showFullAnnou(true)
  }
  return (
    <div className='w-full bg-dg py-2 px-4 mb-2.5 text-sgray text-[10px] leading-[12px] rounded-md'>
      <div className='flex justify-between items-baseline flex-nowrap overflow-ellipsis '>
        <p className='pr-1.5 font-semibold text-[11px]'>{author}</p>
        <span className='overflow-ellipsis max-w-1/2 w-full overflow-hidden whitespace-nowrap text-wht'>
          {subj}
        </span>
      </div>
      <div className='w-full h-0.5 bg-dgray my-2.5'></div>
      <p className='overflow-ellipsis  w-full overflow-hidden text-wht h-12 text-sgray'>{text}</p>
      <div className='flex justify-end'>
        <button onClick={openModal} className='text-dblue mt-2.5'>
          Read
        </button>
      </div>
      {fullAnnou && (
        <div
          onClick={closeModal}
          className='close z-10 fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center'>
          <AnnouncmentModal closeModal={closeModal} author={author} subj={subj} text={text} />
        </div>
      )}
    </div>
  )
}
