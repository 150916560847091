import React from 'react'
import Delete from './icons/Delete'
import ItemWrapper from './ItemWrapper'
import { deleteGroup } from './logic/deleteGroup'
import { useGlobal } from '../GlobalContext'

export default function TaskGroupInput({ taskGroup, setActive, active = false, idx }) {
  const { tasks, setTasks, activeTaskGr, setActiveTaskGr } = useGlobal()

  const handleDelete = async () => {
    const cleanedArray = deleteGroup(tasks, taskGroup.id)
    await setTasks(cleanedArray)
    if (taskGroup.id === activeTaskGr.id) {
      setActiveTaskGr({ ...cleanedArray[0] })
    }
  }
  const handleSelect = () => {
    setActive(taskGroup)
  }
  return (
    <ItemWrapper active={active}>
      <div
        onClick={handleSelect}
        className='h-full w-full px-[15px]  py-[10px] flex items-center text-[11px] justify-between cursor-pointer relative'>
        {active && (
          <button
            onClick={handleDelete}
            className='absolute -top-1 -right-1 glassed w-5 h-5 flex items-center justify-center mr-px focus:outline-none bg-dblue'>
            <Delete color={'#fff'} />
          </button>
        )}
        {active && (
          <button
            onClick={handleDelete}
            className='absolute -top-1 -right-1 rounded-full border-px border-sgray w-5 h-5 flex items-center justify-center mr-px focus:outline-none bg-dblue'>
            <Delete color={'#fff'} />
          </button>
        )}
        <div className='flex flex-col'>
          <div className='flex items-center'>
            <span>{taskGroup.name}</span>{' '}
            <span className='inline-block bg-dgreen rounded-full w-1.5 h-1.5 ml-1.5'></span>
          </div>
          <div className='flex text-[10px]'>
            <span className='text-dblue mr-0.5'>16</span>
            <img className='mr-2' src='./icons/cart.svg' alt='cart' />
            <span className='text-dgreen mr-0.5'>3</span>
            <img className='mr-2' src='./icons/bag.svg' alt='bag' />
            <span className='text-dbrown mr-0.5'>4</span>
            <img src='./icons/blocked.svg' alt='blocked' />
          </div>
        </div>
        <div className='flex '>
          <button>
            <img className='mr-1' src='./icons/start.svg' alt='start' />
          </button>
          <button>
            <img className='' src='./icons/pause.png' alt='pause' />
          </button>
        </div>
      </div>
    </ItemWrapper>
  )
}
