import React from 'react'

export default function Delete({ color = '#fff', width = '10', height = '10' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.14356 1.62148C9.33807 1.62148 9.5 1.78298 9.5 1.98848V2.17848C9.5 2.37898 9.33807 2.54547 9.14356 2.54547H0.856927C0.66193 2.54547 0.5 2.37898 0.5 2.17848V1.98848C0.5 1.78298 0.66193 1.62148 0.856927 1.62148H2.31478C2.61092 1.62148 2.86865 1.41099 2.93527 1.11399L3.01162 0.772992C3.13027 0.308497 3.52075 0 3.96764 0H6.03236C6.47439 0 6.86925 0.308497 6.98352 0.748493L7.06522 1.11349C7.13135 1.41099 7.38907 1.62148 7.6857 1.62148H9.14356ZM8.40302 8.56695C8.55522 7.14846 8.8217 3.7785 8.8217 3.7445C8.83143 3.6415 8.79788 3.544 8.73126 3.4655C8.65977 3.392 8.56933 3.3485 8.46964 3.3485H1.53437C1.4342 3.3485 1.33889 3.392 1.27275 3.4655C1.20565 3.544 1.17258 3.6415 1.17744 3.7445C1.17834 3.75074 1.1879 3.86945 1.20388 4.0679C1.2749 4.94952 1.47269 7.40502 1.6005 8.56695C1.69095 9.42294 2.2526 9.96093 3.06614 9.98043C3.69392 9.99494 4.34067 9.99993 5.002 9.99993C5.62492 9.99993 6.25757 9.99494 6.9048 9.98043C7.74655 9.96593 8.30771 9.43744 8.40302 8.56695Z'
        fill={color}
      />
    </svg>
  )
}
