import React from 'react'

export default function Tasks({ color = '#fff' }) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.50833 1.6665H13.4925C16.0667 1.6665 17.5 3.14984 17.5 5.6915V14.2998C17.5 16.8832 16.0667 18.3332 13.4925 18.3332H6.50833C3.975 18.3332 2.5 16.8832 2.5 14.2998V5.6915C2.5 3.14984 3.975 1.6665 6.50833 1.6665ZM6.73333 5.54985V5.54152H9.22416C9.58333 5.54152 9.87499 5.83319 9.87499 6.19069C9.87499 6.55819 9.58333 6.84985 9.22416 6.84985H6.73333C6.37416 6.84985 6.08333 6.55819 6.08333 6.19985C6.08333 5.84152 6.37416 5.54985 6.73333 5.54985ZM6.73333 10.6166H13.2667C13.625 10.6166 13.9167 10.3249 13.9167 9.96655C13.9167 9.60822 13.625 9.31572 13.2667 9.31572H6.73333C6.37416 9.31572 6.08333 9.60822 6.08333 9.96655C6.08333 10.3249 6.37416 10.6166 6.73333 10.6166ZM6.73334 14.4249H13.2667C13.5992 14.3915 13.85 14.1074 13.85 13.7749C13.85 13.4332 13.5992 13.1499 13.2667 13.1165H6.73334C6.48334 13.0915 6.24167 13.2082 6.10834 13.4249C5.97501 13.6332 5.97501 13.9082 6.10834 14.1249C6.24167 14.3332 6.48334 14.4582 6.73334 14.4249Z'
        fill={color}
      />
    </svg>
  )
}
