import React from 'react'

export default function CheckMarkGreen() {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.14939 10.3549L0.394637 6.60013C0.201137 6.40663 0.201137 6.09313 0.394637 5.89963L1.39939 4.89488C1.59289 4.70138 1.90639 4.70138 2.09989 4.89488L5.85464 8.64963C6.04814 8.84313 6.04814 9.15663 5.85464 9.35013L4.84989 10.3549C4.65639 10.5481 4.34289 10.5481 4.14939 10.3549Z'
        fill='url(#paint0_linear)'
      />
      <path
        d='M3.14494 8.64963L9.89969 1.89488C10.0932 1.70138 10.4067 1.70138 10.6002 1.89488L11.6049 2.89963C11.7984 3.09313 11.7984 3.40663 11.6049 3.60013L4.85019 10.3549C4.65669 10.5484 4.34319 10.5484 4.14969 10.3549L3.14494 9.35013C2.95169 9.15663 2.95169 8.84313 3.14494 8.64963Z'
        fill='url(#paint1_linear)'
      />
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='5.30989'
          y1='9.81013'
          x2='0.884887'
          y2='5.38513'
          gradientUnits='userSpaceOnUse'>
          <stop offset='0.108' stopColor='#0D7044' />
          <stop offset='0.433' stopColor='#11945A' />
        </linearGradient>
        <linearGradient
          id='paint1_linear'
          x1='11.1025'
          y1='2.39735'
          x2='3.64744'
          y2='9.85269'
          gradientUnits='userSpaceOnUse'>
          <stop stopColor='#2AC782' />
          <stop offset='1' stopColor='#21B876' />
        </linearGradient>
      </defs>
    </svg>
  )
}
