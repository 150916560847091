import React from 'react'
import Input from './Input'
export default function SettingsInputs() {
  return (
    <div className='w-full h-full flex flex-col text-[11px] leading-[13px]'>
      <p className='mb-1.5'>Discord Webhooks</p>
      <div className='w-full relative mb-2.5'>
        <Input plchldr='Webhook 1' />
        <button className='text-dblue leading-[12px] text-[10px] absolute top-1/2 right-5 transform -translate-y-1/2 focus:outline-none'>
          Test
        </button>
      </div>
      <div className='w-full relative mb-2'>
        <Input plchldr='Webhook 2' />
        <button className='text-dblue leading-[12px] text-[10px] absolute top-1/2 right-5 transform -translate-y-1/2 focus:outline-none'>
          Test
        </button>
      </div>
      <p className='mb-1.5'>Delays</p>
      <div className='w-full mb-2.5'>
        <Input plchldr='Webhook 1' />
      </div>
      <div className='w-full '>
        <Input plchldr='Webhook 1' />
      </div>
    </div>
  )
}
