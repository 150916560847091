import React from 'react'

export default function Refresh({ color = '#555' }) {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.61538 0L0 0.923077L1.39904 2.32212C0.587741 3.33353 0.0865385 4.60276 0.0865385 6C0.0865385 9.26502 2.73317 11.9135 6 11.9135C9.26683 11.9135 11.9135 9.26502 11.9135 6C11.9135 3.55168 10.4279 1.44591 8.30769 0.548077V1.97596C9.70493 2.77825 10.6442 4.27464 10.6442 6C10.6442 8.56731 8.56731 10.6442 6 10.6442C3.43269 10.6442 1.35577 8.56731 1.35577 6C1.35577 4.95072 1.70553 3.99339 2.29327 3.21635L3.69231 4.61538L4.61538 0Z'
        fill={color}
      />
    </svg>
  )
}
