import React from 'react'
import Input from './Input'

export default function APIKeys() {
  return (
    <div className='flex flex-col bg-dg px-6 py-3 pb-4 rounded-11'>
      <div className='flex justify-between '>
        <span className='text-xs '>API Keys</span>
      </div>
      <span className='h-0.5 w-full bg-dblack my-[11px]'></span>
      <div className='flex flex-col '>
        <div className='mb-2.5'>
          <Input plchldr='2captcha API' />
        </div>
        <div className='mb-2.5'>
          <Input plchldr='anticaptcha API' />
        </div>
        <div className='mb-2.5'>
          <Input plchldr='AYCD API key' />
        </div>
        <div className='mb-2.5'>
          <Input plchldr='AYCD API key 2' />
        </div>
        <div className='flex text-[10px] text-[#555] self-center pt-1'>third party services*</div>
      </div>
    </div>
  )
}
