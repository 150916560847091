import React from 'react'

export default function Play({ color = '#fff', width = '10', height = '10' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 8 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.37535 4.16137C7.98241 4.55567 7.98241 5.44433 7.37535 5.83863L2.21121 9.19284C1.54594 9.62494 0.666502 9.14749 0.666502 8.35421V1.64579C0.666502 0.852505 1.54594 0.375056 2.21121 0.807163L7.37535 4.16137Z'
        fill={color}
      />
    </svg>
  )
}
