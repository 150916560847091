import React from 'react'

export default function Input({
  plchldr = '',
  setValue = () => {
    console.log('No setValue in props')
  },
  value = '',
}) {
  return (
    <input
      value={value}
      onChange={(e) => {
        setValue(e.target.value)
      }}
      type='text'
      className='h-9 w-full py-3 px-5 rounded-md bg-dgray placeholder-[#555] text-[10px] leading-[12px] focus:outline-none'
      placeholder={plchldr}
    />
  )
}
