import React from 'react'

export default function ProxyModal({ closeModal }) {
  return (
    <div className='w-[528px] bg-dg py-4 px-[30px] flex flex-col rounded-[5px]'>
      <div className='flex items-center text-[10px] border-b-2 pb-2 border-dblack'>
        <div className='mr-auto flex flex-col '>
          <h3 className=' text-[#777]'>Add Proxies</h3>
          <h5 className=''>Enter Information</h5>
        </div>
        <button onClick={closeModal} className='close text-dblue'>
          Close
        </button>
      </div>
      <div className='grid  pt-3 pb-5'>
        <textarea
          style={{ resize: 'none' }}
          rows={10}
          className='bg-dgray rounded-md text-[11px] py-3 px-5 placeholder-[#555]'
          placeholder='IP:PORT:USER:PASS'
        />
      </div>
      <button className=' text-[10px] leading-none h-9 self-center  text-dblue  px-[30px] rounded-md bg-dgray hover:bg-lgray transition-colors flex-shrink'>
        Add Proxies
      </button>
    </div>
  )
}
