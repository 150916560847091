import React from 'react'
import LineChartSm from './icons/LineChartSm'

export default function DashboardMainStatItem() {
  return (
    <div className='w-full h-full flex  px-5 py-3 rounded-md bg-dgray justify-between text-[10px]'>
      <div className='flex flex-col '>
        <p className='text-sgray '>
          Checkouts <span className='text-dgreen'>12%</span>
        </p>
        <p className='text-[13px] mb-auto'>1256</p>
        <p className='text-[18px] bg-clip-text text-transparent bg-gradient-to-r from-[#526AE3] to-[#8CA9F3]'>
          Nike
        </p>
      </div>
      <div className='flex flex-col'>
        <LineChartSm />
        <p className='mt-auto text-dgreen'>Last 30 Days</p>
      </div>
    </div>
  )
}
