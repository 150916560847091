import React from 'react'
import MasterCard from './icons/MasterCard'
import Delete from './icons/Delete'
import Edit from './icons/Edit'

export default function ProfileItem({ data }) {
  return (
    <div className='rounded-11 overflow-hidden flex flex-col min-h-[128px] text-[10px] bg-gradient-to-r from-[#526AE3] to-[#8CA9F3]'>
      <div className='flex flex-col  pt-[18px] px-6'>
        <div className='flex justify-between pb-1 '>
          <span>{data.name}</span>
          <span>{data.dueDate}</span>
        </div>
        <div className='flex justify-between pb-6'>
          <span>{data.card.toString().substring(0, 4)} ****</span>
          <span>{data.key}</span>
        </div>
        <div className='flex justify-between'>
          <MasterCard />
          <div className='flex pb-3'>
            <button className='w-[22px] h-[22px] rounded-100 glassed flex items-center justify-center mr-1.5 focus:outline-none'>
              <Delete />
            </button>
            <button
              className={`w-[22px] h-[22px] rounded-100  glassed-blue flex items-center justify-center focus:outline-none`}>
              <Edit />
            </button>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center items-center bg-white backdrop-filter blur-lg bg-opacity-50'>
        <p className='text-[10px] leading-[12px] pt-[9px] pb-1.5 text-[#555]'>{data.cardholder}</p>
      </div>
    </div>
  )
}
