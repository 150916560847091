import React from 'react'
import DropDown from './DropDown'
import Input from './Input'

export default function ProfileModal({ closeModal }) {
  return (
    <div className='w-[528px] bg-dg py-4 px-[30px] flex flex-col rounded-[5px]'>
      <div className='flex items-center text-[10px] border-b-2 pb-2 border-dblack'>
        <div className='mr-auto flex flex-col '>
          <h3 className=' text-[#777]'>Create Profile</h3>
          <h5 className=''>Enter Shipping Information</h5>
        </div>
        <button onClick={closeModal} className='text-dblue'>
          Close
        </button>
      </div>
      <div className='grid grid-rows-5 grid-cols-2 gap-2.5 pt-3 pb-5'>
        <Input plchldr='Profile Name' />
        <Input plchldr='Email' />
        <Input plchldr='First Name' />
        <Input plchldr='Last Name' />
        <div className='col-span-2'>
          <Input plchldr='Adress Line 1' />
        </div>
        <div className='col-span-2'>
          <Input plchldr='Adress Line 2' />
        </div>
        <Input plchldr='Apt/Suite (optional)' />
        <Input plchldr='Phone Number' />
        <Input plchldr='City' />
        <Input plchldr='Postal Code' />
        <DropDown plchldr='State (US Only)' />
        <DropDown plchldr='Country' />
      </div>
      <button className=' text-[10px] leading-none h-9 self-center  text-dblue  px-[30px] rounded-md bg-dgray hover:bg-lgray transition-colors flex-shrink'>
        Create Tasks
      </button>
    </div>
  )
}
