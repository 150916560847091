import React from 'react'
import AnnouncmentItem from './AnnouncmentItem'
import DashboardMainStatItem from './DashboardMainStatItem'

export default function DashboardMain() {
  return (
    <div className='grid_dashboard h-full w-full gap-[14px]'>
      <div className=' h-[50px] flex  justify-center flex-col px-[18px] rounded-md bg-dgray col-span-2 '>
        <h3 className='text-[11px]'>General Information</h3>
        <p className='text-[10px] text-sgray'>Analytics, Updates and new features </p>
      </div>
      <div className=' row-span-2 flex  flex-col px-5 py-3 rounded-md bg-dgray '>
        <div className='flex justify-between items-center mb-2'>
          <h3 className='text-[11px]'>Announcement</h3>
          <p className='text-[10px] text-dblue '>3 new </p>
        </div>
        <AnnouncmentItem />
        <AnnouncmentItem />
      </div>
      <div className='flex  flex-col px-5 py-3 rounded-md bg-dgray col-span-2'></div>
      <DashboardMainStatItem />
      <DashboardMainStatItem />
      <DashboardMainStatItem />
    </div>
  )
}
