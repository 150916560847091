import React from 'react'

export default function Settings({ color = '#fff' }) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0016 11.317C17.2997 11.4753 17.5296 11.7253 17.6915 11.9753C18.0066 12.492 17.981 13.1253 17.6744 13.6837L17.0783 14.6837C16.7632 15.217 16.1755 15.5503 15.5709 15.5503C15.2728 15.5503 14.9407 15.467 14.6682 15.3003C14.4467 15.1587 14.1912 15.1087 13.9187 15.1087C13.0756 15.1087 12.3688 15.8003 12.3432 16.6253C12.3432 17.5837 11.5597 18.3337 10.5803 18.3337H9.42211C8.43422 18.3337 7.65072 17.5837 7.65072 16.6253C7.63369 15.8003 6.92683 15.1087 6.08372 15.1087C5.80268 15.1087 5.54719 15.1587 5.33428 15.3003C5.06176 15.467 4.72111 15.5503 4.43155 15.5503C3.81838 15.5503 3.23075 15.217 2.91565 14.6837L2.32802 13.6837C2.01292 13.142 1.99589 12.492 2.31099 11.9753C2.44725 11.7253 2.70274 11.4753 2.9923 11.317C3.23075 11.2003 3.38405 11.0087 3.52882 10.7837C3.95464 10.067 3.69915 9.12533 2.97526 8.70033C2.13215 8.22533 1.85963 7.16699 2.34506 6.34199L2.91565 5.35866C3.4096 4.53366 4.46562 4.24199 5.31725 4.72533C6.05817 5.12533 7.02051 4.85866 7.45484 4.15033C7.5911 3.91699 7.66775 3.66699 7.65072 3.41699C7.63369 3.09199 7.72737 2.78366 7.88918 2.53366C8.20428 2.01699 8.77487 1.68366 9.39656 1.66699H10.5974C11.2276 1.66699 11.7982 2.01699 12.1133 2.53366C12.2666 2.78366 12.3688 3.09199 12.3432 3.41699C12.3262 3.66699 12.4028 3.91699 12.5391 4.15033C12.9734 4.85866 13.9358 5.12533 14.6852 4.72533C15.5283 4.24199 16.5928 4.53366 17.0783 5.35866L17.6489 6.34199C18.1428 7.16699 17.8703 8.22533 17.0187 8.70033C16.2948 9.12533 16.0393 10.067 16.4736 10.7837C16.6099 11.0087 16.7632 11.2003 17.0016 11.317ZM7.59112 10.0086C7.59112 11.317 8.67269 12.3586 10.0098 12.3586C11.3468 12.3586 12.4028 11.317 12.4028 10.0086C12.4028 8.7003 11.3468 7.6503 10.0098 7.6503C8.67269 7.6503 7.59112 8.7003 7.59112 10.0086Z'
        fill={color}
      />
    </svg>
  )
}
