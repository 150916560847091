import React, { useState } from 'react'
import ProxyMainItem from './ProxyMainItem'
import ProxyModal from './ProxyModal'
import ProxyTest from './ProxyTest'
import { useGlobal } from '../GlobalContext'
export default function ProxyMain() {
  const { proxiesGroups, activeProxiesGr } = useGlobal()
  const [modal, showModal] = useState(false)
  const closeModal = (e) => {
    e.target.classList[0] === 'close' && showModal(false)
  }
  const openModal = (e) => {
    showModal(true)
  }
  return (
    <div className='flex flex-col h-full'>
      <div className='flex h-full flex-shrink max-h-full'>
        <div className='flex flex-col flex-shrink w-full   mr-5 text-[11px] '>
          {proxiesGroups && proxiesGroups?.length > 0 ? (
            <>
              <h3 className='text-[11px] leading-[13px] '>
                {activeProxiesGr.name} ({activeProxiesGr?.proxiesArr?.length})
              </h3>
              <span className='h-0.5 w-full bg-dblack my-[11px]'></span>
              <div className=' flex-shrink overflow-y-scroll  scrl pr-2'>
                {activeProxiesGr &&
                  activeProxiesGr?.proxiesArr.map((proxy, i) => (
                    <ProxyMainItem key={i} data={proxy} />
                  ))}
              </div>
            </>
          ) : (
            <div className='w-full h-full centering'>There is no Proxies group created...</div>
          )}
        </div>
        <ProxyTest openModal={openModal} />
      </div>

      {modal && (
        <div
          onClick={closeModal}
          className='close z-10 fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center'>
          <ProxyModal closeModal={closeModal} />
        </div>
      )}
    </div>
  )
}
