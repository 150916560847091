import React from 'react'

export default function LogoSection() {
  return (
    <div className='flex items-center'>
      <div className='w-12 h-12'>
        <img className='' src='./icons/logo.svg' alt='rhyde' />
      </div>
      <div className='flex flex-col'>
        <span className='text-sm'>RhydeAIO</span>
        <span className='text-[9px]'>Version 1.03</span>
      </div>
    </div>
  )
}
