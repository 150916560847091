import React, { useState } from 'react'
import MonitorsModal from './MonitorsModal'
import MonitorsItem from './MonitorsItem'
import { useGlobal } from '../GlobalContext'
export default function MonitorsMain() {
  const { monitGroups, activeMonitGr } = useGlobal()
  const [modal, showModal] = useState(false)
  const closeModal = (e) => {
    showModal(false)
  }
  return (
    <div className='flex flex-col h-full'>
      {monitGroups && monitGroups?.length > 0 ? (
        <>
          <h3 className='text-[11px] leading-[13px]'>{activeMonitGr.name}</h3>
          <span className='h-0.5 w-full bg-dblack my-[11px]'></span>
          <div className='grid gridMonitors gap-x-[12px] gap-y-[18px] mb-auto overflow-y-scroll scrl'>
            {activeMonitGr &&
              activeMonitGr?.monitorsArr.map((monitor, i) => (
                <MonitorsItem key={i} data={monitor} />
              ))}
          </div>
        </>
      ) : (
        <div className='w-full h-full centering'>There is no Monitors group created...</div>
      )}
      <div className='flex justify-end mt-3'>
        <button
          onClick={() => {
            showModal(true)
          }}
          className='px-[30px] py-3 bg-dgray rounded-[5px] flex items-center justify-center text-dblue text-[10px] leading-[12px] hover:bg-lgray transition-colors'>
          Add Monitor
        </button>
      </div>
      {modal && (
        <div
          onClick={(e) => {
            if (e.target.classList[0] === 'cont') {
              closeModal()
            }
          }}
          className='cont z-10 fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-40 flex justify-center items-center'>
          <MonitorsModal closeModal={closeModal} />
        </div>
      )}
    </div>
  )
}
