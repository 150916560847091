import React from 'react'

export default function Bubble(props) {
  const { size, top, left, i } = props
  const animationDelay = `${Math.random() * 1.4 + i}s`
  return (
    <div
      style={{ width: size, height: size, top, left, animationDelay }}
      className='absolute rounded-full bubble'></div>
  )
}
