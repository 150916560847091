import React from 'react'
import { useInView } from 'react-intersection-observer'
export default function CirlceStatistic({ valid = false, progress }) {
  const [ref, inView] = useInView()

  return (
    <div className='relative w-[30px] h-[30px]'>
      <svg
        style={{
          strokeDasharray: 300,
          strokeDashoffset: inView ? 300 - (300 * progress) / 100 : 300,
          transition: 'all .8s ease-in-out',
        }}
        xmlns='http://www.w3.org/2000/svg'
        width='30'
        height='30'
        viewBox='0 0 100 100'
        str>
        <defs>
          <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
            <stop offset='0%' stopColor='#526AE3' />
            <stop offset='100%' stopColor='#8CA9F3' />
          </linearGradient>
        </defs>
        <circle
          strokeLinecap='round'
          cx='50'
          cy='50'
          r='47'
          stroke='url(#gradient)'
          strokeWidth='6'
          fill='none'
          transform='rotate(270 50 50)'
        />
      </svg>
      <div
        ref={ref}
        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        <p className={valid ? 'text-dgreen' : 'text-dbrown'}>{progress}%</p>
      </div>
    </div>
  )
}
