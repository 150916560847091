import React from 'react'

export default function Checked({ color = '#fff' }) {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.14988 10.3551L0.395125 6.60037C0.201625 6.40687 0.201625 6.09338 0.395125 5.89988L1.39987 4.89512C1.59337 4.70163 1.90687 4.70163 2.10037 4.89512L5.85512 8.64987C6.04862 8.84337 6.04862 9.15687 5.85512 9.35037L4.85037 10.3551C4.65687 10.5484 4.34338 10.5484 4.14988 10.3551Z'
        fill={color}
      />
      <path
        d='M3.14494 8.64987L9.89969 1.89512C10.0932 1.70163 10.4067 1.70163 10.6002 1.89512L11.6049 2.89987C11.7984 3.09337 11.7984 3.40687 11.6049 3.60037L4.85019 10.3551C4.65669 10.5486 4.34319 10.5486 4.14969 10.3551L3.14494 9.35037C2.95169 9.15687 2.95169 8.84337 3.14494 8.64987Z'
        fill={color}
      />
    </svg>
  )
}
