import React, { useState } from 'react'
import { useGlobal } from '../GlobalContext'
import ProfileGroup from './ProfileGroup'
import ProfileGroupModal from './ProfileGroupModal'

export default function ProfilesSideBar() {
  const defaultGroup = {
    name: 'Profile Group 1',
    profilesArr: [
      {
        id: 10101,
        name: 'Profile 1',
        card: '23214978214867',
        key: '441',
        dueDate: '12/23',
        cardholder: 'Max Mustermann',
      },
      {
        id: 10102,
        name: 'Profile 2',
        card: '43214978214867',
        key: '440',
        dueDate: '12/23',
        cardholder: 'Max Mustermann',
      },
      {
        id: 10102,
        name: 'Profile 3',
        card: '23421497821486',
        key: '442',
        dueDate: '12/23',
        cardholder: 'Max Mustermann',
      },
    ],
  }
  const [modal, showModal] = useState(false)
  const { profileGroups, setProfileGroups, activeProfileGr, setActiveProfileGr } = useGlobal()
  const closeModal = (e) => {
    showModal(false)
  }
  const addGroup = (name) => {
    const newGroup = { ...defaultGroup, name, id: Date.now() }
    if (profileGroups.length < 1) {
      setActiveProfileGr(newGroup)
    }
    setProfileGroups((prev) => [...prev, newGroup])
  }
  return (
    <div className='flex flex-col pl-6 py-4  min-h-[550px] h-full bg-dg'>
      <div className='flex justify-between pr-6'>
        <span className='text-xs '>Profile Groups</span>
        <span
          onClick={() => {
            showModal(true)
          }}
          className='text-[10px] add-plus-icon text-dblue cursor-pointer'>
          Add Group
        </span>
      </div>
      <div className='w-full flex-shrink-0 flex pr-6 '>
        <span className='h-0.5 w-full bg-dblack my-[11px]'></span>
      </div>
      <div className='h-[calc(100%-50px)] overflow-y-scroll scrl pr-6'>
        {profileGroups &&
          profileGroups?.length > 0 &&
          profileGroups.map((group, i) => (
            <ProfileGroup
              key={group.id}
              profileGroup={group}
              idx={i}
              active={group.id === activeProfileGr.id}
              setActive={setActiveProfileGr}
            />
          ))}
      </div>
      {modal && (
        <div
          onClick={(e) => {
            if (e.target.classList[0] === 'cont') {
              closeModal()
            }
          }}
          className='cont z-10 fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center'>
          <ProfileGroupModal closeModal={closeModal} addGroup={addGroup} />
        </div>
      )}
    </div>
  )
}
