import React, { useState } from 'react'
import Show from './icons/Show'

export default function SettingsLicense({ license_key }) {
  const [key, showKey] = useState(false)

  return (
    <div className=''>
      <p className='pb-1'>Licence Key</p>
      <div className='flex justify-between w-[140px] items-center mr-auto'>
        {license_key.split('').map((dig, i) => (
          <div key={i} className='h-4 justify-center items-center flex'>
            {key ? (
              <div className=''>{dig}</div>
            ) : (
              <div className='w-[7px] h-[7px] rounded-full bg-[#555]'></div>
            )}
          </div>
        ))}
        <div className='cursor-pointer' onClick={() => showKey(!key)}>
          <Show />
        </div>
      </div>
    </div>
  )
}
